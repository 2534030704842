var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content",attrs:{"id":"content"}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-block"},[_c('h4',{staticClass:"card-title text-center m-t-20"},[_vm._v("Sign up")]),_vm._m(0),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"form-group m-b-20"},[_c('label',{staticClass:"form-label",attrs:{"for":"firstName"}},[_vm._v("First Name")]),_c('ValidationProvider',{attrs:{"name":"First Name","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.registerForm.firstName),expression:"registerForm.firstName"}],staticClass:"form-control form-control-lg",class:classes,attrs:{"type":"text","id":"firstName","required":""},domProps:{"value":(_vm.registerForm.firstName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.registerForm, "firstName", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group m-b-20"},[_c('label',{staticClass:"form-label",attrs:{"for":"lastName"}},[_vm._v("Last Name")]),_c('ValidationProvider',{attrs:{"name":"Last Name","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.registerForm.lastName),expression:"registerForm.lastName"}],staticClass:"form-control form-control-lg",class:classes,attrs:{"type":"text","id":"lastName","required":""},domProps:{"value":(_vm.registerForm.lastName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.registerForm, "lastName", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group m-b-20"},[_c('label',{staticClass:"form-label",attrs:{"for":"userId"}},[_vm._v("Email")]),_c('ValidationProvider',{attrs:{"name":"Email","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.registerForm.email),expression:"registerForm.email"}],staticClass:"form-control form-control-lg",class:classes,attrs:{"type":"email","id":"userId","required":""},domProps:{"value":(_vm.registerForm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.registerForm, "email", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group m-b-20"},[_c('label',{staticClass:"form-label",attrs:{"for":"password"}},[_vm._v("Password")]),_c('ValidationProvider',{attrs:{"vid":"confirm","name":"Password","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.registerForm.password),expression:"registerForm.password"}],staticClass:"form-control form-control-lg",class:classes,staticStyle:{"border-right":"none !important"},attrs:{"type":"password","data-toggle":"password","data-placement":"after","id":"password-indicator-default","name":"password","required":""},domProps:{"value":(_vm.registerForm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.registerForm, "password", $event.target.value)}}}),_c('div',{staticClass:"is0 m-t-5",attrs:{"id":"passwordStrengthDiv"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group m-b-20"},[_c('img',{attrs:{"src":_vm.captchaImg,"alt":""}}),_c('i',{staticClass:"fa fa-redo",staticStyle:{"font-size":"20px","margin-left":"2%","cursor":"pointer"},on:{"click":function($event){return _vm.refreshCaptcha()}}})]),_c('div',{staticClass:"form-group m-b-20"},[_c('label',{staticClass:"form-label",attrs:{"for":"captcha"}},[_vm._v("Enter characters above")]),_c('ValidationProvider',{attrs:{"name":"characters","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.registerForm.captcha),expression:"registerForm.captcha"}],staticClass:"form-control form-control-lg",class:classes,attrs:{"type":"text","id":"captcha","required":""},domProps:{"value":(_vm.registerForm.captcha)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.registerForm, "captcha", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('button',{staticClass:"btn",class:invalid ? 'login-btn-disable' : 'login-btn-enable',attrs:{"type":"button","disabled":invalid},on:{"click":function($event){return _vm.login()}}},[_c('i',{attrs:{"id":"loginBtnLbl"}}),_vm._v("    Sign Up ")])])]}}])})],1)]),_c('QrModel',{attrs:{"qrCode":_vm.qrCode,"email":_vm.registerForm.email,"type":"signup"}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h6',{staticClass:"card-subtitle m-b-20 text-center",staticStyle:{"margin-right":"29%"}},[_vm._v(" Already a member? "),_c('span',{staticClass:"ria-color"},[_c('a',{attrs:{"href":"/magnolia/signin"}},[_vm._v("Sign in now")])])])}]

export { render, staticRenderFns }