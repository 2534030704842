<template>
    <div :class="`text ${direction}`">
        <div :class="`line-horizontal arrow-${direction}`"></div>
    </div>
</template>
<script>

export default {
    name: 'Workflow-arrow',
    props: ['direction'],
    data(){
        return {};
    },
    computed:{},
    methods:{}
}
</script>

<style scoped>
.text{
    height: 56px;
    width: calc(100% + 40px);
    display: flex;
    align-items: center;
}
.right.text{
    justify-content: flex-start;
}
.left.text{
    justify-content: flex-end;
}
.line-horizontal {
    height: 2px;    /* line width */
    width: calc(100% - 12px); /* line length */
    border-radius: 20px;
    background: var(--arrow-color);
    position: relative;
    left: -20px;
}


.arrow-left:after,
.arrow-right:before {
    content: "";
    position: absolute;
    width: 0px;
    height: 0px;
    border: 5px solid transparent;
}
.arrow-left:after,
.arrow-right:before {
    top: -4px;
}
.arrow-left:after {
    left: -15px;
    border-right: 15px solid var(--arrow-color);
}

.arrow-right:before {
    right: -15px;
    border-left: 15px solid var(--arrow-color);
}
</style>