<template>
    <div class="dotted-div">
          <div class="title"> 
           <slot name="title">
              {{data.title || ''}}
          </slot>
          </div>
        <div class="children">
            <div class="child-block">
          <slot name="child1">
            <div class="child">
               <div class="text"> {{data.child1Content}}</div>
            </div>
        </slot>
        </div>
        <div class="arrow"></div>
        </div>  
      </div>
</template>
<script>
export default {
    name: 'DivWithArrow',
    props: ['showArrow', 'data'],
    data(){
        return {};
    },
    computed:{},
    methods:{}
}
</script>

<style scoped>
.dotted-div{
    font-family: 'Nunito Sans';
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    height: 273px;
    border-radius: 8px;
    width: var(--workflowdiv-width);
    border: var(--workflowdiv-border);
}

.child{
    padding: 16px;
    width: 100%;
    height: 56px;
    background: var(--workflowdiv-child-bg-color);
    border-radius: 6px;
}

.text{
    height: 24px;
    color: var(--workflowdiv-child-text-color);
    display: flex;
    justify-content: center;
}
.title{
    text-align: center;
    letter-spacing: 0.15px;
    color: rgba(0, 17, 51, 0.8);
    height:24px;
}
.line-vertical {
    height: 53px; /* line length */
    position: relative;
    background:  var(--workflowdiv-child-bg-color);
    margin: auto auto 38px auto;

}

.arrow{
    height: 62px;
    width: 50%;
    margin-bottom: 26px;
    border: solid var(--workflowdiv-child-bg-color);
    border-width: 0px 3px 3px 0px;
}


.children{
    width: 100%;
}
.child-block{
    padding: 0px 10px;
}
</style>