<template>
  <div id="panel" class="content">
    <div class="row ria page-title profile-title"> {{getSwitchEnv()}} Profile</div>
    <div class="row">
      <p>
      Introduce your {{getSwitchEnv()}} credentials or proceed with Sign up in order to access to the {{getSwitchEnv()}} Profile Details Management.
      <br>
      Personal Emails are not allowed for the sign up in {{getSwitchEnv()}} Portal. It is recommended to register with a secured generic or group mailbox to avoid any changes or disconnection on the email address in the future. 
      <br>
      As soon as the products are included the subscription keys will be displayed under the profile. 
      </p>
    </div>
    <div class="row">
        <router-view class="router"/>
    </div>

  </div>
</template>

<script>
import Constant from "../plugin/Constant";
import Utils from "../plugin/Utils";
export default {
  methods: {
    getSwitchEnv: function() {
      let enc = Utils.encryptStorage().getSession();
      let env =  enc.getItem("env");
      if (env === "Staging") {
        return "Production";
      } else {
        return "Staging";
      }
    },
  },
  
  beforeCreate() {
    let enc = Utils.encryptStorage().getSession();
        if (enc.getItem(Constant.config.PREV_USER_CONFIG) !== null && Object.keys(enc.getItem(Constant.config.PREV_USER_CONFIG)).length > 0) {
            alert("You have already logged-in to alternate environment! Please switch to that environment from Profile page.");
            window.location.assign("/magnolia/profile");
        }
    }
};
</script>
<style scoped>
.profile-title {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  display: flex;
  align-items: center;
}
.router {
    margin-top: 7%;
    height: 100%;
    width: inherit;
}
</style>