<template>
    <div class="modal fade" id="modal-alert">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header" >
          <h4 class="modal-title" v-show="imageTitle">{{ imageTitle }} </h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-hidden="true"
          >
            ×
          </button>
        </div>
        <div class="modal-body" v-show="imagePath">
        <!-- <img id="imageZoom" src="https://picsum.photos/1440/1080" style="width: 100%"  alt="A image to apply the ImageZoom plugin"> -->
          <img :src="imagePath" style="width: 100%"  id="imageZoom" alt="A image to apply the ImageZoom plugin">
        </div>
      </div>
    </div>
    </div>
</template>
<script>
import Utils from '../plugin/Utils'

export default {
    props: ["imageTitle", "imagePath"]
}
</script>