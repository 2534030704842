<template>
  <EditablePage
    v-if="content"
    v-bind:content="content"
    v-bind:config="config"
    v-bind:templateDefinitions="templateDefinitions"
  />
</template>

<script>
const hljs = require("highlight.js");
import config from "../magnolia.config";
import { EditablePage, inEditorEdit } from "../vue-editor";
import {
  getLanguages,
  getCurrentLanguage,
  removeCurrentLanguage,
  getVersion,
} from "../helpers/AppHelpers";
import Constant from "../plugin/Constant";

const getContentUrl = (pathName) => {
  const languages = getLanguages();
  const nodeName = process.env.VUE_APP_SITE_BASENAME;
  const currentLanguage = getCurrentLanguage();
  let path =
    nodeName +
    pathName.replace(
      new RegExp("(.*" + nodeName + "|.html)", "g"),
      ""
    );

  if (currentLanguage !== languages[0]) {
    path = removeCurrentLanguage(path, currentLanguage);
    path += "?lang=" + currentLanguage;
  }

  const version = getVersion(window.location.href);
  if (version) {
    path +=
      path.indexOf("?") > -1 ? "&version=" + version : "?version=" + version;
  }
  return `${
    version
      ? sessionStorage.getItem(Constant.config.MAGNOLIA_URL) +
        process.env.VUE_APP_REST_PAGES_PREVIEW
      : sessionStorage.getItem(Constant.config.MAGNOLIA_URL) +
        process.env.VUE_APP_REST_PAGES
  }${path}`;
};

export default {
  name: "PageLoader",
  components: {
    EditablePage,
  },
  inject:['appState'],
  data: function () {
    return {
      content: undefined,
      config,
      templateDefinitions: {},
      replaceURL: "https://10.25.45.156:8080",
    };
  },
  computed: {
    path: function(){
      return this.appState.overlayProps.path;
    }
  },

  methods: {
    async loadPage() {
      await fetch("/getEndpoint")
        .then((response) => response.json())
        .then((data) => {
          sessionStorage.setItem(Constant.config.SERVER_URL, data.backendURL);
          sessionStorage.setItem(
            Constant.config.MAGNOLIA_URL,
            data.magnoliaURL
          );
        });
    // sessionStorage.setItem(
    //     Constant.config.SERVER_URL,
    //     "https://10.25.45.156:6500/riaservice/"
    //   );
    //   sessionStorage.setItem(
    //     Constant.config.MAGNOLIA_URL,
    //     "https://localhost:8080" 
    //   );

      const path = this.path || window.location.pathname;
      const contentResponse = await fetch(getContentUrl(path));

      let content = await contentResponse.json();

      /*let updatedContent = JSON.stringify(content);
      
      if (updatedContent.includes(this.replaceURL)) {
        updatedContent = updatedContent.replace(
          this.replaceURL,
          sessionStorage.getItem(Constant.config.MAGNOLIA_URL)
        );
      } else {
        if (updatedContent.indexOf("src=") > 1) {
          let position = updatedContent.indexOf("src=") + 6;
          updatedContent = [
            updatedContent.slice(0, position),
            sessionStorage.getItem(Constant.config.MAGNOLIA_URL),
            updatedContent.slice(position),
          ].join("");
        }
      }
      content = JSON.parse(updatedContent); */

      if (
        content != null &&
        content.hasOwnProperty("main") &&
        content.main.hasOwnProperty("0") &&
        content.main["0"].hasOwnProperty("richText")
      ) {
        content.main["0"].richText = this.updateImagePath(
          content.main["0"].richText
        );
        content.main["0"].richText = this.formatText(
          content.main["0"].richText
        );
      } else {
        console.log("Content no main or richText is available");
      }

      if (inEditorEdit) {
        const templateDefinitionsResponse = await fetch(
          sessionStorage.getItem(Constant.config.MAGNOLIA_URL) +
            process.env.VUE_APP_REST_TEMPLATE_DEFINITION +
            content["mgnl:template"]
        );
        const templateDefinitions = await templateDefinitionsResponse.json();
        this.templateDefinitions = templateDefinitions;
      }
      
      this.content = content;
    },
    updateImagePath(pageContent) {
      let parser = new DOMParser();
      let htmlDoc = parser.parseFromString(pageContent, "text/html");
      let images = htmlDoc.getElementsByTagName("img");
      const magUrl = sessionStorage.getItem(Constant.config.MAGNOLIA_URL);
      Array.from(images).forEach(img => img.src = `${magUrl}/${ img.src.substring(img.src.indexOf("magnoliaPublic")).replace("/%22", "")}`) 
      let xlx = htmlDoc.getElementsByTagName("a");
      Array.from(xlx).forEach(x => {
        if(['.xlsx', '.csv', '.xml', '.json'].some(type => x.href.includes(type))){
           x.href = `${magUrl}/${ x.href.substring(x.href.indexOf("magnoliaPublic")).replace("/%22", "")}`
        }
      });
      return htmlDoc.documentElement.innerHTML;
    },
    formatText(pageContent) {
      let parser = new DOMParser();
      let htmlDoc = parser.parseFromString(pageContent, "text/html");
      let elements = htmlDoc.querySelectorAll("div.format-text p");
      elements.forEach(ele => {
        ele.innerHTML = `<pre><code>${JSON.stringify(JSON.parse(ele.innerHTML), null, 4)}</pre></code>`;
        hljs.highlightElement(ele);
      })
      return htmlDoc.documentElement.innerHTML;
    },
  },
  mounted() {
    this.loadPage();
  },
  updated() {
    if (inEditorEdit) window.parent.mgnlRefresh();
  },
};
</script>
