<template>
  <!-- begin #content -->
  <div id="content" class="content">
    <EditableArea style="font-family: Nunito Sans; margin-left: 25px; margin-top: 10px;" v-if="main" v-bind:content="main" />
    <div class="table-content">
        <table
              class="table table-striped table-bordered page-content"
              id="methodListTable"
            >
              <thead>
                <tr>
                  <th width="20%">Method</th>
                  <th class="text-nowrap">API Name</th>
                </tr>
              </thead>
              <tbody>
                <tr class="odd gradeX" v-for="(api, index) in apiList" v-bind:key="index">
                  <td> {{api.method}} </td>
                  <td> <router-link :to="{
                          path: '/apiPage',
                          query: { id: api.name }
                        }" style="color: rgba(0, 17, 51, 0.8);"> {{api.description}} </router-link> </td>
                </tr>
              </tbody>
            </table>
            <br><br>
        </div>
  </div>
</template>

<script>
import { EditableArea } from "../vue-editor";
import AxiosService from '../plugin/AxiosService';
import Constant from '../plugin/Constant';
import Utils from '../plugin/Utils';

export default {
  name: "MethodsList",
  data() {
       return {
           apiList: {}
       }
   }, 
  components: {
    EditableArea,
  },
  props: ["title", "metadata", "main"],
  created() {
    document.title = this.title;
    let self = this;
      AxiosService.get(Constant.url.GET_ALL_API).then(result => {
          self.apiList = result;
      });
  },
  mounted() {
    Utils.createDataTable('methodListTable');
  },
};
</script>
<style>
.auth-class {
  background: #f2f3f4;
  border-radius: 3px;
  padding: 1px;
  margin-top: 0%;
}

.auth-content {
  margin-top: 1%;
  margin-left: 1%;
}

.table-content {
  width: 90%;
  margin-left: 2%;
  margin-top: 2%;
  margin-bottom: 5%;
}

.pagination > li > a, div.dataTables_wrapper div.dataTables_info, 
div.dataTables_wrapper div.dataTables_length label,
div.dataTables_wrapper div.dataTables_filter label {
    font-family: 'Nunito Sans' !important;
}
</style>

