"use strict";
import Constant from "./Constant";
import Utils from './Utils';
const axios = require("axios");
const https = () => require('https');
const uuidv4 = require('uuid/v4');
var baseURL = '';
var config = {
    mode: "no-cors",
    headers: {
        "Content-Type": "application/json",
        "session-id": window.sessionStorage.getItem(Constant.config.SESSION_ID)
    },
    withCredentials: true,
    credentials: "same-origin",
    data: '',
    method: '',
    url: ''
};

function getSessionURL(params) {
    let url = sessionStorage.getItem(Constant.config.SERVER_URL);
    if (url == "null") {
        return baseURL;
    } else if (!Utils.isEmpty(url)) {
        return url;
    }
    return baseURL;
}
const get = async function(uri) {
    config.url = await getSessionURL() + uri;
    config.method = 'get';
    config.headers["session-id"] = window.sessionStorage.getItem(Constant.config.SESSION_ID);
    console.log(config.headers);
    return axios(config).then((response) => {
        if (response.headers['content-type'] === 'application/json') {
            response.data = Utils.unescapeHTML(response.data);
            return JSON.parse(JSON.stringify(response.data));
        } else {
            throw "Invalid Content Type " + response.headers['content-type'];
        }
    }).catch(function(error) {
        console.error(error);
        return error;
    });
};
const getURL = async function(uri) {
    config.url = uri;
    config.method = 'get';
    return axios(config).then((response) => {
        response.data = Utils.unescapeHTML(response.data);
        return JSON.parse(JSON.stringify(response.data));
    }).catch(function(error) {
        console.warn('URL is not configured !');
        return null;
    });
};

const getEndpoint = function(uri) {
    config.url = uri;
    config.method = 'get';
    return axios(config);
}

const post = async function(uri, formData) {
    let id = await uuidv4();
    let headers = {
        requestId: id + "-" + new Date().getTime(),
        signature: Utils.generateSignature(id, formData)
    }
    config.url = await getSessionURL() + uri;
    config.method = 'post';
    config.data = formData;
    Object.assign(config.headers, headers);
    return axios(config)
        .then(function(response) {
            /*if (response.headers['content-type'] === 'application/json') {
                var data = response.data;
                if ((data.hasOwnProperty('responseCode') && data.responseCode != "00") || response.status != 200) {
                    console.info(data);
                }
                if (!Utils.isEmpty(response.headers['requestid']) && !Utils.isEmpty(response.headers['signature'])) {
                    let isValidSignature = Utils.verifySignature(response.headers['requestid'], response.headers['signature'], data)
                    let isValidTime = Utils.getMinDiff(response.headers['requestid']);
                    if (isValidSignature && isValidTime) {
                        data = Utils.unescapeHTML(data);
                        return data;
                    } else {
                        throw "not valid signature and time : " + isValidSignature + " : " + isValidTime;
                    }
                }
            } else {
                throw "Invalid Content Type " + response.headers['content-type'];
            }*/
            return response.data;
        })
        .catch(function(error) {
            console.error(error);
            return error;
        });
};
const postAPI = async function(uri, formData) {
    config.url = await getSessionURL() + uri;
    config.method = 'post';
    config.data = formData;
    let id = await uuidv4();
    let headers = {
        requestId: id + "-" + new Date().getTime(),
        signature: Utils.generateSignature(id, formData)
    }
    Object.assign(config.headers, headers);
    return axios(config)
        .then(function(response) {
            /*if (response.headers['content-type'] === 'application/json') {
                if (!Utils.isEmpty(response.headers['requestid']) && !Utils.isEmpty(response.headers['signature'])) {
                    let isValidSignature = Utils.verifySignature(response.headers['requestid'], response.headers['signature'], response.data)
                    let isValidTime = Utils.getMinDiff(response.headers['requestid']);
                    if (isValidSignature && isValidTime) {
                        response.data = Utils.unescapeHTML(response.data);
                        return response;
                    } else {
                        throw "not valid signature and time : " + isValidSignature + " : " + isValidTime;
                    }
                }
            } else {
                throw "Invalid Content Type " + response.headers['content-type'];
            }*/
            return response;
        })
        .catch(function(error) {
            console.info(error);
            return error;
        });
};
const getToken = function(uri, formData) {
    config.url = uri;
    config.method = 'post';
    config.data = formData;
    return axios(config)
        .then(function(response) {
            var data = response.data;
            if ((data.hasOwnProperty('responseCode') && data.responseCode != "00") || response.status != 200) {
                console.error(data);
            }
            return data;
        })
        .catch(function(error) {
            console.error(error);
            return error;
        });
};
export default {
    get,
    post,
    getToken,
    postAPI,
    getURL,
    getEndpoint
}