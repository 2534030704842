<template>
  <div class="workflow-1">
      <div-with-two-child-vue key="123456" :data="workflowContent.block1" class="block1"/>
      <div-with-two-child-vue class="block2" :data="{}">
        <template slot="child1">
            <arrowVue :direction="'right'" class="arrow-blue" />
        </template>
       <template slot="child2">
            <arrowVue :direction="'left'" class="arrow-black"  />
      </template>
      </div-with-two-child-vue>
      <div-with-two-child-vue class="block3"  :data="workflowContent.block2"/>
    <div-with-two-child-vue class="block4" :data="{}">
        <template slot="child1">
            <arrowVue :direction="'right'" class="arrow-orange" />
        </template>
       <template slot="child2">
            <arrowVue :direction="'left'" class="arrow-black" />
      </template>
     </div-with-two-child-vue>
     <div-with-two-child-vue :data="workflowContent.block3" :showArrow="true" class="block5">
        <template slot="title">
            <img class="riaLogo" :src="appState.getImage('riaLogoDark')" :style="{'min-width': '150px', width: '60%'}"/>
        </template>
     </div-with-two-child-vue>
  </div>
</template>
<script>
import divWithTwoChildVue from './div-with-two-child.vue';
import arrowVue from './arrow.vue';
export default {
    name: 'WorkflowId1',
    inject: ['appState'],
    props: ['workflowContent'],
    components:{
        divWithTwoChildVue,
        arrowVue
    },
    data(){
        return {};
    },
    computed:{},
    methods:{},
    async mounted(){
        await swtichTheme.init();
    }
}
</script>

<style scoped>
.workflow-1{
    display: flex;
    justify-content: space-around;
    width: 100%;
}
.block1{
    --workflowdiv-child-bg-color: #00AAFF;
    --workflowdiv-border: 1px dashed #00AAFF;
} 
.block5{
     --workflowdiv-border: 1px dashed #ff6100;
     --workflowdiv-child-bg-color: #ff6100;
}
.block1, .block5{
    --workflowdiv-width: 25%;
    --workflowdiv-child-text-color: #ffffff;
}
.block2, .block4{
    --workflowdiv-border: none;
    --workflowdiv-width: 15%;
}

.block3{
    --workflowdiv-border: none;
    --workflowdiv-width: 30%;
    --workflowdiv-child-bg-color: rgba(0, 17, 51, 0.05);
    --workflowdiv-child-text-color: rgba(0, 17, 51, 0.8);
}

.arrow-black{
    --arrow-color: black;
}

.arrow-orange{
    --arrow-color: #ff6100;
}
.arrow-blue{
    --arrow-color: #00AAFF;
}

</style>