<template>
  
  <div id= "content" class="content">
      <h1>{{workflowData.title}}</h1>
      <div v-if="workflowData.description" class="workflow-description Paragraph">
            <ParagraphVue  :richText="workflowData.description"  @switchTheme="switchTheme"></ParagraphVue>
       </div>
      <div class="workflow-info">
          <div class="workflow-steps">
            <ul>
                <li v-for="(step, index) in workflowData.steps" :key="step.title" :class="{'workflow-active-step': activeStepperIndex === index}" @click="onStepClick(index)">
                    <a >{{index+1}}. {{step.title}}</a>
                </li>
            </ul>
          </div>
          <div v-if="activeStep" class="step-info">
              <div v-if="activeStep.description" class="workflow-step-description Paragraph">
                  <ParagraphVue  :richText="activeStep.description" @switchTheme="switchTheme"></ParagraphVue>
              </div>
              <div class="workflow-img-container">
                   <component :is="getWorkflowComponent(activeStep.workflowContent.id)" :workflowContent="activeStep.workflowContent"></component>
              </div>
              <div class="workflow-endpoint-btn" @click="onEndpointClick">
                  {{viewEndpointText}}
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import Constant from "../plugin/Constant";
import PageLoader from '../helpers/PageLoader.vue';
import UnauthorizedVue from "../common/UnAuthorized.vue";
import {cloneDeep} from 'lodash';
import WorkflowId1 from './workflow-compoents/WorkflowId1.vue';
import WorkflowId2 from './workflow-compoents/WorkflowId2.vue';
import WorkflowId3 from './workflow-compoents/WorkflowId3.vue';
import ParagraphVue from "../components/Paragraph.vue";
import ApiPage from "../api/ApiPage.vue";
const WorkflowComponents = {
    "workflow-id-1": WorkflowId1,
    "workflow-id-2": WorkflowId2,
    "workflow-id-3": WorkflowId3,
}
export default {
    props: ["title", "main", "config", "isPage"],
    inject:["appState"],
    data(){
        return {
            workflowData: {},
            activeStepperIndex: null,
        }
    },
    components:{
        ParagraphVue
    },
    methods:{
        updateImagePath(pageContent) {
            let parser = new DOMParser();
            let htmlDoc = parser.parseFromString(pageContent, "text/html");
            let images = htmlDoc.getElementsByTagName("img");
            for (let i = 0; i < images.length; i++) {
                let imgPath = images[i].src;
                images[i].src =
                sessionStorage.getItem(Constant.config.MAGNOLIA_URL) +
                "/" +
                imgPath
                    .substring(imgPath.indexOf("magnoliaPublic"))
                    .replace("/%22", "");
            }
            return htmlDoc.documentElement.innerHTML;
        },
        switchTheme:async function(){
            await swtichTheme.init();
        },
        onStepClick:function(event){
           this.activeStepperIndex = event;
        },
        getWorkflowComponent(id){
            return WorkflowComponents[id];
        }
        ,
        isPathAccessible: function(path){
            const [paths] = path.split('?');
            const [, ...roots] = paths.split('/');
            if(roots[roots.length - 1] === 'apiPage'){
                return true;
            }
            let accessTree = this.appState.accessablePaths;
            let i;
            for(i= 0; i < roots.length && accessTree[`/${roots[i]}`] !== undefined; i++){
                accessTree = accessTree[`/${roots[i]}`];
            }
            return i === roots.length && Boolean(accessTree);
        },
        onEndpointClick: function(){
            this.appState.showOverlay = true;
            try{
            const propsString = this.activeStep.endpoint.split('?')[1];
            let props = {};
            if(propsString){
                props = propsString.split(",").reduce((overlayProps,prop) => {
                const [key, value] = prop.split("=");
                overlayProps[key] = value;
                return overlayProps;
                }, {});
            }
            this.appState.overlayProps = props;
            }catch(e){
                console.error("Error in computing query props", e)
            }
            this.appState.overlayProps.path = this.activeStep.endpoint;
            if(!this.appState.isPathAccessible(this.activeStep.endpoint)){
                this.appState.overlayComponent = UnauthorizedVue;
            } else if(this.activeStep.endpoint.includes('apiPage?')){
                const {id} = this.appState.overlayProps
                const lastIndexOf_ = id.lastIndexOf('_');
                this.appState.overlayProps.parent = id.substring(lastIndexOf_ + 1);
                this.appState.overlayComponent = ApiPage;
            }else{
                this.appState.overlayComponent  = PageLoader; 
            }

        },
        loadImage: function () {
            const riaImages = localStorage.getItem(Constant.config.RIA_IMAGES);
            this.images = riaImages ? JSON.parse(riaImages) : {};
        },
        getImage: function (name) {
            try {
                return Utils.isEmpty(this.images[name]) ? "" : this.images[name];
            } catch (error) {
                console.error(error);
                return "";
            }
        }, 
    },
    computed: {
        activeStep: function(){
            if(this.activeStepperIndex !== null){
                return this.workflowData.steps[this.activeStepperIndex];
            }
            return null;
        },
        viewEndpointText: function(){
            return this.workflowData.viewEndpointBtnTxt || 'View endpoint';
        }
    },
    mounted(){
        this.workflowData = cloneDeep(this.main.workflowInfo);
        this.workflowData.steps = this.main.workflowInfo.steps['@nodes'].map(key =>{
            const step = this.main.workflowInfo.steps[key];
            if(step.description){
                step.description = this.updateImagePath(step.description);
            }
            return  step;
        });
           if(this.workflowData && this.workflowData.steps){
            if(this.workflowData.steps.length){
                this.activeStepperIndex = 0;
            }
        }
        this.domain = localStorage.getItem(Constant.config.MAGNOLIA_URL);
    }
}
</script>

<style scoped>
.workflow-info{
    height: 100%;
}

.step-info{
    height: 60%;
}
.content{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    overflow-wrap: break-word;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: unset !important;
}
.workflow-steps{
    overflow: auto;
}
ul{
    display: flex;
    flex-direction: row;
    list-style-type: none;
    background: #FFFFFF;
    box-shadow: 0px 3px 15px rgb(0 17 51 / 5%);
    height: 68px;
}
li{
    flex-grow: 1;
    text-align: center;
    margin: 5px;
    display: inline-flex;
    min-width: 100px;
    cursor: pointer;
}
a {
    margin:auto;
}
img{
    min-width: 1000px;
    width: 100%;
    height: 100%;
}
.workflow-img-container{
    overflow-x:auto ;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
h1{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #001133;

}
.step-info{
    padding: 5px;
}
.step-info > div{
    padding: 5px;
}
.workflow-active-step{
    border-bottom: 4px solid #FF6100;
    font-weight: 700;
    color: rgba(0, 17, 51, 0.8);
}
.workflow-endpoint-btn{
    height: 32px;
    color: #FF6100;
    font-weight: 700;
    font-size: 16px;
    margin-top: 20px;
    cursor: pointer;
}
.content{
    overflow: scroll
}
</style>