<template>
  <div>
    <!-- START modal -->
      <div class="modal fade" id="modalLoading" tabindex="-1" role="dialog" aria-hidden="true" >
        <div class="modal-dialog modal-dialog-centered" role="document">
              <p class="first-loader"></p>
        </div>
      </div>
  <!-- END modal -->
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.first-loader {
  position: fixed;
  transform: translate(-50%, -50%);
  border: 10px solid #ff6100;
  border-top: 10px solid #808080bf;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  animation: spin 1.3s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>