import { render, staticRenderFns } from "./WorkflowId3.vue?vue&type=template&id=c8b1d0e8&scoped=true&"
import script from "./WorkflowId3.vue?vue&type=script&lang=js&"
export * from "./WorkflowId3.vue?vue&type=script&lang=js&"
import style0 from "./WorkflowId3.vue?vue&type=style&index=0&lang=css&"
import style1 from "./WorkflowId3.vue?vue&type=style&index=1&id=c8b1d0e8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8b1d0e8",
  null
  
)

export default component.exports