<template>
  <div id="app">
    <component :is="layout"> 
      <router-view :key="$route.path" />
    </component>
    <div class="overlay" v-if="appState.showOverlay">
        <div class="overlay-content">
          <div class="overlay-close" >
            <span @click="onOverlayClose"> X </span>
          </div>
          <component :is="appState.overlayComponent" />
        </div>
      </div>
    <div id="mobile-popup">
      <div id="popup-container">
        <img id="popup-img" src="../public/images/MobileWarning.png"/>
        <div id="popup-title">Mobile view unavailable</div>
        <div id="popup-desc">We’re working on improving our responsive view. Please view this website on your desktop</div>
      </div>
    </div>
  </div>
</template>

<script>
import { EditableArea } from "./vue-editor";
import AppState from './app-state';

export default {
  name: "app", 
   data() {
    return { 
      default_layout: 'Admin',
      isWebView: false,
      appState: new AppState()
    }; 
  },  
  components: {
    EditableArea,
  },  
  props: ["startMenuName", "metadata", "main", "extras"],
  provide(){
    return {
      appState: this.appState,
    }
  },
  computed: { 
    layout() {
      return (this.$route.meta.layout || this.default_layout) + "-layout";
    },
  },
  methods: {
    onOverlayClose: function(){
      this.appState.showOverlay = false;
      this.appState.overlayComponent = null;
      this.appState.overlayProps = {};
    }, 
  },
  async mounted() {  

  },   
  beforeCreate: function () {
  }, 
  watch: {
  }
};
</script>

<style>
#app {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
#mobile-popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  height: 100%;
  width: 100%;
  background: #FFFFFF;
}
div#mobile-popup {
  display: none;
}
@media only screen and (max-device-width:480px) {
  div#mobile-popup {
    display: block;
  }
}
#popup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
#popup-img {
  margin-bottom: 32px;
}
#popup-title,
#popup-desc {
  font-family: "Nunito Sans";
  letter-spacing: 0.15px;
  text-align: center;
  padding: 8px 24px;
}
#popup-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: rgba(0, 17, 51, 0.8);
}
#popup-desc {
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 17, 51, 0.6);
}

.overlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 2000; /* should be higher than all , for sidebar we have 1500 hence making this 2000 */
  cursor: pointer; /* Add a pointer on hover */
  display: flex;
  justify-content: center;
  align-items: center;
}
.overlay.show{
    display: flex;
    justify-content: center;
    align-items: center;
}
.overlay-content{
    background-color: white;
    height: 80%;
    width: 80%;
    overflow: auto;
    flex-direction: column;
    display: flex;
}
.overlay-close {
    float: right;
    margin: 10px 10px 5px 10px;
    display: inline-flex;
    justify-content: end;
}
.overlay-close span {
  color: #001133;
  font-size: large;
}
.overlay-close > *{
 margin: 10px;
}
.overlay-close img{
  height: 25px;
  width: 20px;
}
.overlay-content > .content{
  margin:0px !important;
  height: 100%;
  padding:15px !important;
}
.overlay-content .content .wrapper{
  padding: 5px !important;
}

</style>