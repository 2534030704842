import Utils from './plugin/Utils';
import Constant from './plugin/Constant';
export default class AppState {
    showOverlay = false;
    overlayComponent =  null;
    overlayProps = {};
    domain =  null;
    isLocalStorageSet  =  false;
    isWebView =  false;
    isMobileView =  false;
    isTabletView =  false;
    images =  null;
    accessiblePaths= {};

    loadImage() {
        const riaImages = localStorage.getItem(Constant.config.RIA_IMAGES);
        this.images = riaImages ? JSON.parse(riaImages) : {};
    }

    getImage(name) {
        if(this.images === null){
            this.loadImage();
        }
        try {
            return Utils.isEmpty(this.images[name]) ? "" : this.images[name];
        } catch (error) {
            console.error(error);
            return "";
        }
    }

    isPathAccessible(path){
        const [paths] = path.split('?');
        const [, ...roots] = paths.split('/');
        if(roots[roots.length - 1] === 'apiPage'){
            return true;
        }
        let accessTree = this.accessiblePaths;
        let i;
        for(i= 0; i < roots.length && accessTree[`/${roots[i]}`] !== undefined; i++){
            accessTree = accessTree[`/${roots[i]}`];
        }
        return i === roots.length && Boolean(accessTree);
    }

}