"use strict";

const codeRequest = function(selectedLanguage, selectedVariant, data, url, headers) {
    var request = {
        request: {
            url: "http://localhost:9000/sandbox/codegenerator",
            method: "POST",
            header: [{
                key: "Content-Type",
                value: "application/json"
            }],
            body: {
                mode: "raw",
                raw: data
            }
        },
        language: selectedLanguage,
        variant: selectedVariant
    };
    return request;
}

const codeGenerator = async function(requestData) {
    const codegen = await
    import ( /* webpackPrefetch: false */ 'postman-code-generators');
    const sdk = await
    import ( /* webpackPrefetch: false */ 'postman-collection');

    var result;
    var request = new sdk.Request(requestData.request);
    var options = {
        indentType: 'Tab'
    };
    try {
        codegen.convert(requestData.language, requestData.variant, request, options, function(error, snippet) {
            if (error) {
                console.error(error);
            }
            result = snippet;
        });
    } catch (error) {
        console.error(error);
    }
    return result;
}

export default {
    codeGenerator,
    codeRequest
}