<template>
  <!-- #modal-alert -->
  <div class="modal fade" id="modal-alert">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header" >
          <h4 class="modal-title">{{ qrCode ==="" ? 'Two-Factor Authentication Code' : 'Two-Factor Authentication (2FA) Setup' }} </h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-hidden="true"
          >
            ×
          </button>
        </div>
        <div class="modal-body" v-show="qrCode">
          <h5>Users with Admin roles are required to setup 2FA</h5>
          <br />
          <p>Instructions for setting up 2FA:</p>
          <p>1. Install and open a 2FA Authenticator app on your device</p>
          <p>2. Create a new account by scanning the QR-code below</p>
          <p>
            3. When created successfully, enter a token from the authenticator
            app and click the Activate button below
          </p>
          <p>
            4. You will be logged out, please re-login with the one-time code
            provided by the Authenticator app
          </p>
          <br />
          <img :src="qrCode" class="qr-code" />
        </div>

        <ValidationObserver v-slot="{ invalid }">
          <form @submit.prevent="onSubmit" autocomplete="off">
            <div class="form-group m-b-20">
              <label for="userId" class="form-label">Token</label>
              <ValidationProvider
                name="Token"
                :rules="{ required: true, min: 6 }"
                v-slot="{ errors, classes }"
              >
                <input
                  type="text"
                  class="form-control form-control-lg"
                  id="firstName"
                  :class="classes"
                  required
                  placeholder="Token"
                  v-model="verifyForm.qrToken"
                  @keyup.enter="verifyCode()"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="modal-footer">
              <BaseTimer v-show="false"></BaseTimer>
              <a href="javascript:;" class="btn btn-white" data-dismiss="modal"
                >Close</a
              >
              <button
                type="button"
                class="btn btn-success"
                id="verifyToken"
                data-dismiss="modal"
                :disabled="invalid"
                @click="verifyCode()"
              >
                <i :class="loading ? 'fa fa-spinner fa-spin' : ''" ></i> &nbsp;&nbsp; {{ qrCode === "" ? 'Submit' : 'Active' }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>

  <!-- end panel -->
</template>

<script>
import BaseTimer from "./BaseTimer";
import AxiosService from "../plugin/AxiosService";
import Constant from "../plugin/Constant";
import Utils from '../plugin/Utils';
export default {
  data() {
    return {
      verifyForm: {
        qrToken: "",
        email: "",
      },
      loginBtn: "loginBtnLbl",
      loading: false
    };
  },
  components: {
    BaseTimer,
  },
  props: ["qrCode", "email", "type"],
  methods: {
    verifyCode: function () {
      this.loading = true;
      let self = this;
      this.verifyForm.email = this.email;
      $("#" + this.loginBtn).addClass(this.loadingClass);
      $(".btn").attr("disabled", true);
      let enc = Utils.encryptStorage().getSession();
      this.verifyForm.ocp_Apim_Sas_Token = enc.getItem(Constant.config.LOGIN_COOKIE).ocp_Apim_Sas_Token;
      AxiosService.post(Constant.url.VERIFY_CODE, this.verifyForm).then(
        (response) => {
                  console.log(response);
          if (response.responseCode == "00") {

            if (Utils.isEmpty(response.responseData["User Subscriptions"])) {
                window.location.assign("/magnolia/invalidSubscription");
            } else {
              $.gritter.add({
                title: "Success",
                text: "You have logged in successfully",
              });


              enc.setItem(Constant.config.USER_CONFIG, response.responseData);
              enc.setItem(Constant.config.USER_ID, self.email);

              $('#modal-alert').appendTo("body").modal('hide');

              if (self.type === "signin") {
                Utils.idleLogout();
                window.location.assign("/magnolia/dashboard");
              } else {
                window.location.assign("/magnolia/ria-portal/login");
              }
            }
            
          } else {
            $.gritter.add({
              title: "Error",
              text: response.responseMsg,
            });
          }
          this.loading = false;
          $("#" + this.loginBtn).removeClass(this.loadingClass);
          $(".btn").attr("disabled", false);
        }
      );
    },
  },
};
</script>
<style scoped>
.form-group {
  width: 70%;
  margin: 0 70px;
}
</style>