<template>
  <!-- begin #content -->

  <div id="content" class="content content-full-width">
    <!-- begin vertical-box -->
    <div class="vertical-box">
      <!-- begin vertical-box-column -->
      <div class="vertical-box-column">
        <!-- begin vertical-box -->
        <div class="vertical-box">
          <!-- begin vertical-box-row -->
          <div class="vertical-box-row">
            <!-- begin vertical-box-cell -->
            <div class="vertical-box-cell">
              <!-- begin vertical-box-inner-cell -->
              <div class="vertical-box-inner-cell">
                <!-- begin scrollbar -->
                <div id="maincontent-scrollbar" data-scrollbar>
                  <rapi-pdf id="thedoc" style="display: none"> </rapi-pdf>
                  <div class="invoice ria body-dark">
                    <!-- begin invoice-company -->
                    <div
                      class="
                        invoice-company
                        text-inverse
                        f-w-600
                        api-page-title
                      "
                    >
                      <span>{{ transformMethodName(openapi.info.title) }}</span>
                      <span class="pull-right hidden-print">
                        <!-- v-show="apiStyle.isApiDoc" -->
                        <button
                          type="button"
                          class="
                            btn btn-white btn-sm
                            dropdown-toggle
                            m-b-10
                            p-l-5
                            header-button
                          "
                          data-toggle="dropdown"
                          style="margin-left: -5%"
                        >
                          <i class="t-plus-1 fa fa-download"></i> API Spec
                        </button>
                        <ul class="dropdown-menu" role="menu">
                          <li>
                            <a
                              href="javascript:;"
                              @click="convertApiSpec('swagger_2')"
                              >Swagger v.2.0</a
                            >
                          </li>
                          <li>
                            <a
                              href="javascript:;"
                              @click="convertApiSpec('openapi_3')"
                              >Open API v.3.0.0</a
                            >
                          </li>
                          <li>
                            <a
                              href="javascript:;"
                              @click="convertApiSpec('raml')"
                              >RAML</a
                            >
                          </li>
                          <li>
                            <a
                              href="javascript:;"
                              @click="convertPostmanCollection()"
                              >Postman v.2.1.0</a
                            >
                          </li>
                        </ul>

                        <a
                          href="javascript:;"
                          class="
                            btn btn-sm btn-white
                            m-b-10
                            p-l-5
                            header-button
                          "
                          @click="generatePdfDocument()"
                          style="margin-left: 1%; color: black !important"
                          ><i
                            class="
                              fa fa-file-pdf
                              t-plus-1
                              text-danger
                              fa-fw fa-lg
                            "
                          ></i>
                          API Document</a
                        >
                      </span>
                    </div>
                    <div
                      class="invoice-header ria page-content-dark"
                      v-html="openapi.info.description"
                      style="
                        background-color: transparent !important;
                        margin-left: -1% !important;
                        white-space: break-spaces;
                      "
                    ></div>
                    <ApiTabs
                      :requestBody="requestBody"
                      :show-example="!!selectedExample"
                      class="ria api-tab-bg-dark"
                      style="font-family: Poppins, sans-serif; font-size: 14px"
                    ></ApiTabs>
                    <div class="tab-content ria body-dark">
                      <div class="invoice-content tab-pane" id="default-tab-1">
                        <span class="span-head" v-text="requestBodyMsg"></span>
                        <form :id="requestSchemaForm" autocomplete="off"></form>
                      </div>
                      <div
                        class="
                          invoice-content
                          tab-pane
                          fade
                          active
                          ria
                          tab-active-dark
                          show
                        "
                        id="default-tab-2"
                        @click="addFormInputEvent"
                      >


                        <span
                          class="span-head ria table-td-dark"
                          v-if="paramObject.header"
                          >Header Parameters</span
                        >
                        <form :id="headerSchemaForm" autocomplete="off" />

                        <span
                          class="span-head ria table-td-dark"
                          v-if="paramObject.path"
                          >Path Parameters</span
                        >
                        <form :id="pathSchemaForm" autocomplete="off" />

                        <span
                          class="span-head ria table-td-dark"
                          v-if="paramObject.query"
                          >Query Parameters</span
                        >
                        <form :id="querySchemaForm" autocomplete="off" />

                      </div>
                      <div
                        id="default-tab-4"
                        class="invoice-content tab-pane"
                        >
                       <span v-if="responseBodyMsg" class="span-head"> {{  responseBodyMsg }}</span>
                        <form
                            v-show="!responseBodyMsg"
                            :id="responseSchemaForm"
                            autocomplete="off"
                            />
                        </div>
                      <div
                        class="invoice-content tab-pane fade"
                        id="default-tab-3"
                      >
                        <div class="dropdown" v-if="selectedExample && Object.keys(apiExamples).length > 1">
                          <button
                              type="button"
                              class="
                                btn btn-sm
                                m-b-10
                                p-l-5
                                examples-button
                              "
                              :class="{show: showExampleDropdown}"
                              @click="showExampleDropdown = !showExampleDropdown"
                              @blur="onBlurOfExampleDropdown"
                            >
                              <div>
                                {{selectedExample}}
                              </div>
                              <i class="arrow"></i>
                            </button>
                        
                          <ul class="dropdown-menu examples-list" :class="{show: showExampleDropdown}">
                            <li v-for="example in Object.keys(apiExamples)" :key="example">
                              <a
                                href="javascript:;"
                                @mousedown="onExampleSelectionChange(example)"
                                >{{example}}</a
                              >
                            </li>
                          </ul>
                      </div>

                        <div>
                          <span class="span-head ria table-td-light">{{
                            selectedExample
                          }}</span>

                          <json-viewer
                            v-if="selectedExample"
                            :value="extractData(apiExamples[selectedExample])"
                            :expanded="true"
                            :expand-depth="999"
                            class="ria sidebar-code-bg-light"
                            :copyable="{
                              copyText: 'copy',
                              copiedText: 'copied',
                              timeout: 2000,
                            }"
                          ></json-viewer>
                          <br />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <!-- end scrollbar -->
              </div>
              <!-- end vertical-box-inner-cell -->
            </div>
            <!-- end vertical-box-cell -->
          </div>
          <!-- end vertical-box-row -->
        </div>
        <!-- end vertical-box -->
      </div>
      <!-- end vertical-box-column -->
      <!-- begin vertical-box-column -->
      <div class="vertical-box-column width-450 ria sidebar-bg-dark">
        <!-- begin vertical-box -->
        <div class="vertical-box">
          <!-- begin wrapper -->
          <div class="wrapper text-white">
            <!-- :style="apiStyle.rightSide.top" -->
            <ResponseTabs
              v-show="isResponse"
              :responseObject="responseObject"
            ></ResponseTabs>

            <!--   Right Column Top Box   -->
          </div>
          <!-- end wrapper -->
          <!-- begin vertical-box-row -->
          <div class="vertical-box-row text-white">
            <!-- :style="apiStyle.rightSide.body" -->
            <!-- begin vertical-box-cell -->
            <div class="vertical-box-cell">
              <!-- begin vertical-box-inner-cell -->
              <div class="vertical-box-inner-cell" id="panel">
                <!-- begin scrollbar -->
                <div
                  data-slim-scrollbar="true"
                  data-height="100%"
                  class="panel-body"
                >
                  <!-- :style="apiStyle.rightSide.body" -->
                  <span
                    class="span-head ria text-dark"
                    v-show="!isResponse"
                    v-text="serverURL"
                  ></span>
                  <br />
                  <span
                    class="span-head ria text-dark"
                    v-show="!isResponse"
                    v-text="endpoint"
                    style="overflow-wrap: break-word"
                  ></span>
                  <ResponseBody
                    v-show="isResponse"
                    :responseObject="responseObject"
                    :class="copyClass"
                  ></ResponseBody>
                  <json-viewer
                    :value="jsonData"
                    class="ria sidebar-bg-light"
                    :expand-depth="10"
                    :copyable="{
                      copyText: 'copy',
                      copiedText: 'copied',
                      timeout: 2000,
                    }"
                    :expanded="true"
                    v-show="jsonFlag"
                  ></json-viewer>
                  <!-- :style="apiStyle.rightSide.body" -->
                  <span
                    v-show="codeFlag"
                    class="copyBtn pull-right"
                    :class="copyClass"
                    style="margin-top: 0%; margin-right: -24%; cursor: pointer"
                    data-clipboard-action="copy"
                    data-clipboard-target="#copyData"
                    >copy</span
                  >
                  <div v-show="codeFlag">
                    <prism-editor
                      id="copyData"
                      class="prism-editor-wrapper ria prism-light"
                      style="
                        color: white;
                        font-family: Poppins, sans-serif;
                        width: 100%;
                      "
                      v-model="code"
                      :highlight="highlighter"
                      line-numbers
                    ></prism-editor>
                  </div>
                </div>
                <!-- end scrollbar -->
              </div>
              <!-- end vertical-box-inner-cell -->
            </div>
            <!-- end vertical-box-cell -->
          </div>
          <!-- end vertical-box-row -->
          <!-- begin wrapper -->
          <div class="wrapper text-white d-flex justify-content-between">
            <!-- :style="apiStyle.rightSide.bottom" -->
            <div class="language-select" style="width: 200px !important">
              <Select2
                v-model="languageId"
                :options="languageJson"
                data-style="btn-inverse"
                @select="generateCode($event)"
              />
            </div>
            <div class="px-2">
              <button
                type="button"
                class="btn btn-inverse"
                style="
                  border-color: #e2e7eb;
                  color: #444;
                  background-color: #fff;
                "
                data-click="panel-reload"
                @click="callApi"
                id="btnTryItOut"
              >
                Try It Out
              </button>
            </div>
            <div class="language-select" style="width: 200px !important">
              <Select2
                v-model="environment"
                :options="environmentList"
                data-style="btn-inverse"
                id="environment"
                @select="setEnviroment($event)"
              />
            </div>
          </div>
          <!-- end wrapper -->
        </div>
        <!-- end vertical-box -->
      </div>
      <!-- end vertical-box-column -->
    </div>
    <!-- end vertical-box -->
    <ModalLoding />
  </div>
  <!-- end #content -->
</template>

<script>
import AxiosService from "../plugin/AxiosService";
import Constant from "../plugin/Constant";
import SwaggerParser from "swagger-parser";
import JsonViewer from "vue-json-viewer/ssr";
import "vue-json-viewer/style.css";
import language from "../../public/json/language.json";
import Select2 from "v-select2-component";
import SDK from "../plugin/CodeGenerator";
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-okaidia.css";
import Utils from "../plugin/Utils";
import ApiTabs from "./ApiTabs";
import ResponseTabs from "./ResponseTabs";
import ResponseBody from "./ResponseBody";
import ModalLoding from "../common/ModalLoading";
import jsf from '../plugin/json-schema';
const jsonToYaml = require("json-to-pretty-yaml");
import Scrollbar from 'smooth-scrollbar';

var _ = require("lodash");
const yaml = require("js-yaml");
var jp = require("jsonpath");

export default {
  data() {
    return {
      apiData: {
        type: "",
        definitionFile: "",
      },
      openapi: {
        info: {
          title: "",
          description: "",
        },
      },
      smoothScrollOptions: {
        continuousScrolling : true,
        alwaysShowTracks: true
      },
      requestSchemaForm: "requestSchemaForm",
      headerSchemaForm: "headerSchemaForm",
      querySchemaForm: "querySchemaForm",
      pathSchemaForm: "pathSchemaForm",
      responseSchemaForm: "responseSchemaForm",
      availableForms: ["requestSchemaForm","headerSchemaForm","querySchemaForm","pathSchemaForm","responseSchemaForm"],
      jsonData: {},
      languageJson: language.results,
      languageId: 0,
      code: "",
      language: "",
      codeFlag: false,
      jsonFlag: false,
      requestBody: true,
      copyClass: "jv-container jv-light ria",
      copiedClass: "copied-btn",
      environment: 1,
      environmentList: [
        { id: 0, text: " Select Environment ", disabled: true },
        { id: 1, text: "Sandbox" },
        { id: 2, text: "Production" },
        { id: 3, text: "Customer Integration" },
        { id: 4, text: "Certification" },
      ],
      apiExamples: {},
            selectedExample: null,
      showExampleDropdown: false,

      requestBodyMsg: "",
      responseBodyMsg: "",
      apiStyle: {
        title: {},
        header: {},
        isApiSpec: true,
        isApiDoc: true,
        table: {
          thead: {},
        },
        rightSide: {
          top: {},
          body: {},
          bottom: {},
        },
      },
      paramObject: {},
      endpoint: "",
      serverURL: "",
      statusCode: "",
      isResponse: false,
      responseObject: { data: {} },
      lodaing: false,
    };
  },
  inject:['appState'],
  components: {
    JsonViewer,
    Select2,
    PrismEditor,
    ApiTabs,
    ResponseTabs,
    ResponseBody,
    ModalLoding,
  },
  beforeCreate() {},
  created() {
    let basePath = process.env.PUBLIC_PATH;
    var fileref = document.createElement("link");
    fileref.setAttribute("rel", "stylesheet");
    fileref.setAttribute("type", "text/css");
    fileref.setAttribute("href", "../../" + basePath + "/css/ria/style.css");
    document.getElementsByTagName("head")[0].appendChild(fileref);
    //App.init();
    this.getApiData();
  },
  mounted() {
    let self = this;
    $(document).on("keyup keypress", 'form input[type="text"]', function (e) {
      if (e.which == 13) {
        e.preventDefault();
        return false;
      }
    });
    Scrollbar.init(document.querySelector("#maincontent-scrollbar"), this.smoothScrollOptions);
    $("[data-slim-scrollbar=true]").each(function () {
      generateSlimScroll($(this));
    });
    setTimeout(() => {
      handleSlimScroll();
    }, 500);
    Utils.initalizeClipboardJs();
    $(".select2-container").removeAttr("style");
    let enc = Utils.encryptStorage().getSession();
    let result = enc.getItem(Constant.config.UI_CONFIG);
    if (!Utils.isEmpty(result)) {
      let value = _.filter(result, function (object) {
        return object.propertyName === Constant.config.RIA_CONFIG;
      })[0];
      if (!_.isEmpty(value)) {
        let propertyObject = JSON.parse(value.propertyValue);
        if (
          _.has(propertyObject, "body") &&
          _.has(propertyObject.body, "api")
        ) {
          self.apiStyle = propertyObject.body.api;
        } else {
          console.error(
            "header property is missing : %s ",
            JSON.stringify(propertyObject)
          );
        }
      } else {
        console.error(
          "Object value is empty %s for key : %s ",
          value,
          Constant.config.RIA_CONFIG
        );
      }
    }
  },
  methods: {
     onBlurOfExampleDropdown: function(event){
      this.showExampleDropdown = false;
    },
    onExampleSelectionChange: function(example){
       this.selectedExample = example;
    },
    extractData: function (obj) {
      if(!obj || _.isEmpty(obj)){
        return null;
      }
      if (obj.hasOwnProperty("value")) {
        return _.isArray(obj.value) ? obj.value[0] : obj.value;
      } else if (
        obj.hasOwnProperty("Response") &&
        obj.Response.hasOwnProperty("value")
      ) {
        return _.isArray(obj.Response.value)
          ? obj.Response.value[0]
          : obj.Response.value;
      } else if (
        obj.hasOwnProperty("example-1") &&
        obj["example-1"].hasOwnProperty("value")
      ) {
        return obj["example-1"]["value"];
      } else if (obj["Currency Service Exception"]) {
        return obj["Currency Service Exception"];
      } else {
          var firstKey = Object.keys(obj)[0];
          if(firstKey != undefined && obj[firstKey].hasOwnProperty("value")) {
            return obj[firstKey].value;
          }
        return obj;
      }     
    },
    createDynamicArrayField: function () {
      let self = this;
      setTimeout(() => {
        $(".addArrayBtn").click((e) => {
          let id = e.currentTarget.id;
          let count = 0;
          let element = document.getElementById(id);
          let dom_name = element.getAttribute("data-name");
          let tagName = id.split("]")[1];
          $(
            "input[id$='" +
              tagName +
              "'], textarea[id$='" +
              tagName +
              "'], select[id$='" +
              tagName +
              "']"
          ).each(function (i, el) {
            count++;
          });
          let parentId = id.replace("]", count - 1 + "]");
          $(
            "input[id^='" +
              parentId +
              "'], textarea[id$='" +
              parentId +
              "'], select[id$='" +
              parentId +
              "']"
          ).each(function (i, el) {
            $(this)
              .append("<br />")
              .clone()
              .prop("id", id.replace("]", count + "]"))
              .prop("name", dom_name.replace("]", count + "]"))
              .attr("style", "margin-top: 2%;")
              .insertAfter(this);
          });
          self.addFormInputEvent();
          self.generateFormData();
        });

        $(".removeArrayBtn").click((e) => {
          let id = e.currentTarget.id;
          let count = 0;
          let tagName = id.split("]")[1];
          $(
            "input[id$='" +
              tagName +
              "'], textarea[id$='" +
              tagName +
              "'], select[id$='" +
              tagName +
              "']"
          ).each(function (i, el) {
            count++;
          });
          if (count > 1) {
            let element = document.getElementById(
              id.replace("]", count - 1 + "]")
            );
            element.remove();
          }
          self.generateFormData();
        });
          if(_.isEmpty(this.apiExamples)){
            this.selectedExample = null;
          }else{
            const examples = Object.keys(this.apiExamples);
            this.selectedExample = examples[0];
          }
      }, 500);
    },
    async callApi () {
      this.showLoadingModal();
      let self = this;
      let target = $("#panel");
      let content_type, method, operationId, header, token;
      let authorization = Constant.config.AUTHORIZATION;

      token = {
        authorization: sessionStorage.getItem(Constant.config.JWT_TOKEN),
      };

      $.each(
        jp.query(this.openapi, Constant.jsonPath.CONTENT_TYPE)[0],
        function (key, value) {
          content_type = key;
        }
      );
      $.each(
        jp.query(this.openapi, Constant.jsonPath.METHOD)[0],
        function (key, value) {
          method = key;
          return false;
        }
      );

      operationId = jp.query(this.openapi, Constant.jsonPath.OPERATION_ID)[0];

      if (!_.isEmpty(token)) {
        header = Object.assign(
          token,
          $("#" + this.headerSchemaForm).jsonFormValue()
        );
      } else {
        header = "";
      }

      //Utils.addSpinner(target);
      let apiRequest = {
        requestData: JSON.stringify(
          $("#" + this.requestSchemaForm).jsonFormValue()
        ),
        contentType: content_type,
        url: self.endpoint,
        method: method,
        requestId: operationId,
        enviroment: $("#environment option:selected").text(),
        headers: header,
      };
      delete apiRequest.headers["authorization"]; // remove authorization from headers request since we get 400bad request on prod! (2021-12-03)

      const file = document.querySelector('.input-file');
      if(file != undefined) {
        await Utils.base64(file.files[0]).then(result => {
          apiRequest.requestData = result;
        })
      }

      AxiosService.postAPI(Constant.url.API_CALL_URI, apiRequest)
        .then((result) => {
          let response = result.hasOwnProperty("response")
            ? result.response
            : result;
          //Utils.removeSpinner(target);
          self.isResponse = true;
          self.codeFlag = false;
          self.responseObject = response;
          this.jsonFlag = false;
          this.hideLoadingModal();
        })
        .catch(() => {
          this.hideLoadingModal();
        });
    },

    convertApiSpec: function (specType) {
      let self = this;
      if (specType === "raml") {
        Utils.fileDownload(
          jsonToYaml.stringify(this.openapi),
          this.openapi.info.title + "_" + _.now() + ".raml"
        );
      } else {
        let fromSpec = _.has(this.openapi, "swagger")
          ? "swagger_2"
          : "openapi_3";
        Utils.apiSpecConverter(fromSpec, specType, this.openapi).then(
          (result) => {
            Utils.fileDownload(
              result.stringify(),
              self.openapi.info.title + "_" + _.now() + ".json"
            );
          }
        );
      }
    },

    // Transform Method Name from camelCase to regular text
    transformMethodName(name) {
      return name.length > 5 && !name.trim().includes(" ")
        ? _.startCase(name)
        : name;
    },

    convertPostmanCollection: function () {
      Utils.postmanCollection(this.openapi);
    },

    generatePdfDocument: function () {
      let docEl = document.getElementById("thedoc");
      docEl.setAttribute("include-example", true);
      docEl.setAttribute("pdf-schema-style", "table");
      docEl.generatePdf(this.openapi);
    },

    highlighter: function (code) {
      return highlight(code, languages.js, this.language); //returns html
    },

    setEnviroment: function (event) {
      let self = this;
      let value;
      if (_.isEmpty(event)) {
        value = _.replace(
          _.lowerCase($("#environment option:selected").text()),
          " ",
          "_"
        );
      } else {
        value = _.replace(_.lowerCase(event.text), " ", "_");
      }
      Utils.getEnviromentURL(value).then((result) => {
        //self.serverURL = result;
        setTimeout(() => {
          self.generateEndpoint();
        }, 2000);
      });
    },

    getAndSetAgentId() {
      let enc = Utils.encryptStorage().getSession();
      let userConfig = enc.getItem(Constant.config.USER_CONFIG);

      // get agentId from user config
      let riaAgent = JSON.parse(userConfig.note)["ria-Agents"][0];
      riaAgent = riaAgent[Object.keys(riaAgent)[0]];

      // set(replace) agentId
      document.querySelector("input[name=ria-AgentId").value = riaAgent;
    },

    /**
     * Fetch API data by API id
     */
    getApiData: function () {
      let menuId = '';
      if(this.appState.showOverlay){
        menuId = this.appState.overlayProps.id.split("_")[0];
      }else{
        menuId = this.$route.query.id.split("_")[0];
      }

      document.title = menuId;

      AxiosService.get(Constant.url.GET_API + menuId).then((result) => {
        if (!_.isEmpty(result) && _.isArray(result)) {
          this.apiData = result[0];
        } else {
          console.error("Response data is empty or is not array " + result);
        }
      });
    },

    /**
     * Generate code base on selected language
     */
    generateCode: async function ({ id, text }) {
      var selectedLanguage = {};
      var flag = false;
      $.each(this.languageJson, function (parentKey, parentValue) {
        $.each(parentValue.children, function (childKey, childValue) {
          if (childValue.id === id) {
            flag = true;
            return false;
          }
        });
        if (flag) {
          selectedLanguage = parentValue;
          return false;
        }
      });
      var result = await SDK.codeGenerator(
        await SDK.codeRequest(
          selectedLanguage.value,
          text,
          JSON.stringify(this.jsonData),
          this.serverURL + this.endpoint,
          $("#" + this.headerSchemaForm).jsonFormValue()
        )
      );
      this.language = selectedLanguage.value;
      this.code = result;
      this.showComponets("CODE");
    },

    /**
     * Parse open api
     */
    parseApiData: function () {
      let self = this;
      let formJson = {};
      this.jsonData = {};
      this.extractApiData().then((result, error) => {
        if (!_.isEmpty(result)) {
          self.openapi = result;
          let schema = jp.query(result, Constant.jsonPath.REQUEST_BODY);
          let examples = jp.query(result, Constant.jsonPath.RESPONSE_EXAMPLES);
          let parameters = jp.query(result, Constant.jsonPath.PARAMETERS);
          let responseSchema = jp.query(result, Constant.jsonPath.RESPONSE_BODY);
          if (!_.isEmpty(schema)) {
            formJson["schema"] = schema[0].schema;
            $("#" + self.requestSchemaForm).jsonForm(formJson, undefined, result.info.title);
            self.generateFormData();
            self.requestBody = true;
            self.apiExamples = $.extend(schema[0].examples, examples[0]);
            self.addFormInputEvent();
            self.requestBodyMsg = "";
          } else {
            self.requestBodyMsg = "This API has no request body.";
            self.requestBody = false;
          }
          if (!_.isEmpty(examples)) {
            self.apiExamples = examples.reduce((apiExamples,e) =>({...apiExamples,...e}), {}); 
          }
          if (!_.isEmpty(responseSchema)) {
            formJson["schema"] = responseSchema[0].schema;
            if(!formJson["schema"].properties || !Object.keys(formJson["schema"].properties).length) {
              self.responseBodyMsg = "This API does not give any info as response.";
            } else {
              self.responseBodyMsg = "";
              $("#" + self.responseSchemaForm).jsonForm(formJson, true, result.info.title);
              $("#" + self.responseSchemaForm +" > div > table > tbody > tr").each(function(){
              $(this).find("input,textarea,select,a").each(function () {
                      $(this).remove();
                  });
              });
              $("#default-tab-4>span:first-of-type").remove();
              $(".jsonform-error-requestRouteValues").remove();
            }
           
          }
          if (!_.isEmpty(parameters)) {
            formJson = {};
            self.paramObject = Utils.getParameters(parameters[0]);
            formJson["schema"] = Utils.generateHeaderForm(
              self.paramObject.header
            );
            if (!_.isEmpty(self.paramObject.header)) {
              $("#" + self.headerSchemaForm).jsonForm(formJson, undefined, result.info.title);
            }

            formJson = {};

            formJson["schema"] = Utils.generateHeaderForm(
              self.paramObject.query
            );
            if (!_.isEmpty(self.paramObject.query)) {
              $("#" + self.querySchemaForm).jsonForm(formJson, undefined, result.info.title);
            }

            formJson = {};
            formJson["schema"] = Utils.generateHeaderForm(
              self.paramObject.path
            );
            if (!_.isEmpty(self.paramObject.path)) {
              $("#" + self.pathSchemaForm).jsonForm(formJson, undefined, result.info.title);
            }
            setTimeout(() => {
              this.setSubscriptionKey();
              Utils.addInputValidation();
              this.getAndSetAgentId();
            }, 800);

            this.headerMsg = "";
          } else {
            this.headerMsg = "This API has no header parameters.";
          }
          setTimeout(() => {
            this.setEnviroment();
          }, 800);
        }

        this.availableForms.forEach(formName => {
          this.secondScrollAssociation(formName);
        });
        
        swtichTheme.init();
      });
      this.createDynamicArrayField();
    },

    secondScrollAssociation: function (schemaName) {
      $(".scrollTop_" + schemaName).scroll(function(){
        $(".scrollBottom_" + schemaName).scrollLeft($(".scrollTop_" + schemaName).scrollLeft());
      });
      $(".scrollBottom_" + schemaName).scroll(function(){
          $(".scrollTop_" + schemaName).scrollLeft($(".scrollBottom_" + schemaName).scrollLeft());
      });
    },

    setSubscriptionKey: async function () {
      let self = this;
      let enc = await Utils.encryptStorage().getSession();
      let userConfig = await enc.getItem(Constant.config.USER_CONFIG);
      await setTimeout(async () => {
        let option = {};
        await $(".router-link-exact-active").each(function () {
          // console.log($(this).data("product"));
        });
        let productName = '';
        if(self.appState.showOverlay){
          productName = self.appState.overlayProps.parent;
        }else{
          productName = $("." + self.$route.query.id).attr("product");
        }
        await $.each(userConfig["User Subscriptions"], function (key, value) {
          if (value["Product Name"] === productName) {
            option +=
              '<option value="' +
              value["Subscription Primary Key"] +
              '">' +
              "Primary-" +
              value["Subscription Primary Key"] +
              "</option>";
            option +=
              '<option value="' +
              value["Subscription Secondary Key"] +
              '">' +
              "Secondary-" +
              value["Subscription Secondary Key"] +
              "</option>";
          }
        });
        await $("input[name*='Ocp-Apim-Subscription-Key']").each(function () {
          $(this).replaceWith(
            '<select id="' +
              $(this).attr("id") +
              '" name="' +
              $(this).attr("name") +
              '" class="ria form-control-light">' +
              option +
              "</select>"
          );
        });
        await Utils.addInputValidation();
        await swtichTheme.init();
      }, 800);
      //$("input[name='Ocp-Apim-Subscription-Key']").val("your value");
    },

    /**
     * Add form input event
     */
    addFormInputEvent: function () {
      $("#" + this.requestSchemaForm + " input, select").on(
        "keyup",
        this.generateFormData
      );

      $("#" + this.requestSchemaForm + " input, select").on(
        "change",
        this.generateFormData
      );

      $("#" + this.pathSchemaForm + " input").on(
        "keyup",
        this.generateEndpoint
      );
      $("#" + this.pathSchemaForm + " input, select").on(
        "change",
        this.generateEndpoint
      );
      $("#" + this.querySchemaForm + " input").on(
        "keyup",
        this.generateEndpoint
      );
      $("#" + this.querySchemaForm + " input, select").on(
        "change",
        this.generateEndpoint
      );
      $("#" + this.headerSchemaForm + " input").on(
        "keyup",
        this.generateEndpoint
      );
      $("#" + this.headerSchemaForm + " input, select").on(
        "change",
        this.generateEndpoint
      );
    },

    /**
     * Dynamically Generate API endpint base on input parameters
     */
    generateEndpoint: function () {
      this.isResponse = false;
      let queryData = $("#" + this.pathSchemaForm).jsonFormValue();
      let path = jp.query(this.openapi, Constant.jsonPath.PATH);
      let self = this;

      $.each(path[0], function (key, value) {
        path = key;
      });
      if (!_.isEmpty(queryData) && !_.isEmpty(path)) {
        $.each(queryData, function (key, value) {
          path = _.replace(path, "{" + key + "}", value);
        });
      }

      let pathData = $("#" + this.querySchemaForm).jsonFormValue();
      if (!_.isEmpty(pathData) && !_.isEmpty(path)) {
        let queryVarArray = [];
        $.each(pathData, function (key, value) {
          if (value != "false") {
            queryVarArray.push(key + "=" + value);
          }
        });
        if (!_.isEmpty(queryVarArray))
          path = path + "?" + queryVarArray.join("&");
      }
      if (!_.isEmpty(path)) {
        let regExp = new RegExp("/", "gi");
        if ((path.match(regExp) || []).length > 1) {
          let endURI = path.substring(1, path.length);
          let app = $("." + self.$route.query.id).data("app");
          //this.endpoint = app.includes("SendOrdersV2_sendmoney") ? "/" + app.replace("SendOrdersV2_sendmoney", "SendOrders").replace("SendOrdersV1_sendmoney", "SendOrders") + "/" + endURI : "/" + endURI; 
          this.endpoint =  "/"+ endURI; //app.includes("money") ? "/" + endURI : "/" + app + "/" + endURI.substring(endURI.indexOf("/") + 1);
        } else { 
          this.endpoint = path;
        }
      }
      Utils.addInputValidation();
    },

    /**
     * Generate Form Data
     */
    generateFormData: function () {
      let data = $("#" + this.requestSchemaForm).jsonFormValue();
      if (!_.isEmpty(data)) {
        this.requestBody = true;
        this.jsonData = $("#" + this.requestSchemaForm).jsonFormValue();
        this.showComponets("JSON");
      }
      Utils.addInputValidation();
    },

    /**
     * Check API data is Yaml or Json base on type
     * API data is extracted.
     */
    extractApiData: function () {
      let self = this;
      return new Promise((resolve, reject) => {
        if (_.upperCase(self.apiData.type) === "YAML") {
          const jsonApi = yaml.load(self.apiData.definitionFile);
          SwaggerParser.validate(jsonApi, (err, apiDefinition) => {
            if (err) {
              console.error(err);
              reject(err);
            } else {
              resolve(apiDefinition);
            }
          });
        } else {
          resolve(JSON.parse(self.apiData.definitionFile));
        }
      });
    },

    /**
     * Base on input hide or show componets
     */
    showComponets: function (componetName) {
      this.isResponse = false;
      if (componetName === "JSON") {
        this.jsonFlag = true;
        this.codeFlag = false;
      } else {
        this.jsonFlag = false;
        this.codeFlag = true;
      }
    },
    showLoadingModal() {
      $("#modalLoading").modal({ keyboard: false });
    },
    hideLoadingModal() {
      setTimeout(() => {
        $("#modalLoading").modal("hide");
      }, 800);
    },
  },
  destroyed() {},
  watch: {
    $route() {
      $("#" + this.querySchemaForm)
        .find("input")
        .val(""); // dummy fix - clear value of query param when route is changed (RIA_DP-410)
      this.getApiData();
    },
    apiData() {
      $("#" + this.querySchemaForm).each(function () {
        this.reset();
      });
      this.parseApiData();
      this.apiExamples = [];
      this.selectedExample = null;
      this.showExampleDropdown = false;
      $("#" + this.requestSchemaForm).empty();
      $("#" + this.headerSchemaForm).empty();
      $("#" + this.querySchemaForm).empty();
      $("#" + this.pathSchemaForm).empty();
      $("#" + this.responseSchemaForm).empty();
    },
  },
};
</script>
<style>
.jv-container.jv-dark {
  background: #2d353c;
  white-space: nowrap;
  color: #f8f9fa;
  font-size: 14px;
  font-family: Poppins, sans-serif;
}
.jv-container.jv-light {
  background: #f8f9fa;
  white-space: nowrap;
  color: #2d353c;
  font-size: 14px;
  font-family: Poppins, sans-serif;
}
.jv-container.jv-light .jv-item.jv-string {
  color: #ff6100;
  word-break: break-word;
  white-space: normal;
  tab-size: 4;
  font-family: Poppins, sans-serif;
}
.jv-container.jv-dark .jv-item.jv-string {
  color: #afe24a;
  word-break: break-word;
  white-space: normal;
  tab-size: 4;
  font-family: Poppins, sans-serif;
}
.copied-btn {
  background: #2d353c;
  color: #7a7f84;
  font-size: 14px;
  font-family: Poppins, sans-serif;
}
.jv-container.jv-dark .jv-key {
  color: #f8f9fa;
  margin-right: 4px;
}
.jv-container.jv-dark .jv-item.jv-object {
  color: #f8f9fa;
}
.jv-container.jv-dark .jv-item.jv-array {
  color: #f8f9fa;
}
.jv-container.jv-dark .jv-button {
  color: #f8f9fa;
}
.jv-container.jv-light .jv-key {
  color: #2d353c;
  margin-right: 4px;
}
.jv-container.jv-light .jv-item.jv-object {
  color: #2d353c;
}
.jv-container.jv-light .jv-item.jv-array {
  color: #2d353c;
}
.jv-container.jv-light .jv-button {
  color: #fff;
}
#copyData.prism-dark > div.prism-editor__container > pre {
  color: white !important;
}
#copyData.prism-light > div.prism-editor__container > pre,
#copyData.prism-light > div.prism-editor__container > pre > .operator,
#copyData.prism-light > div.prism-editor__container > pre > .punctuation {
  color: black !important;
}
#copyData.prism-light > div.prism-editor__container > pre > .function {
  color: #91872b !important;
}
.prism-editor-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  -o-tab-size: 1.5em;
  tab-size: 1.5em;
  -moz-tab-size: 1.5em;
}
.prism-editor-wrapper .prism-editor__editor {
  position: relative;
  pointer-events: none;
  color: white !important;
  overflow: none !important;
}
.panel-loading .panel-body .panel-loader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #ffffff7a;
  opacity: 0.9;
  -webkit-animation: fadeIn 0.2s;
  animation: fadeIn 0.2s;
  z-index: 1020;
  border-radius: 0 0 4px 4px;
}
.spinner-small {
  border: 2px solid rgba(0, 0, 0, 0.05);
  border-top: 2px solid #000000;
  height: 30px;
  width: 30px;
  margin: -15px 0 0 -15px;
}
.row {
  margin: 0 0px;
}
.table thead th,
.table > thead > tr > th {
  color: #242a30;
  font-weight: 600;
  border-bottom: 0px solid transparent !important;
  border-style: hidden !important;
}
.pull-right {
  float: right !important;
}
.tab-content {
  padding: 15px;
  margin-bottom: 20px;
  background: #fff;
  border-radius: 3px;
  border-style: solid;
  border-color: #f0f3f4;
  border-width: thin;
  font-family: Poppins, sans-serif;
  font-size: 14px;
}
.span-head {
  font-size: 14px;
  font-family: Poppins, sans-serif;
  color: #242a30;
}
.wrapper {
  padding: 18px;
}
.tab-content {
  border: none;
  background: unset !important;
  white-space: pre-line;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link,
.nav-pills > li.active > a,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover,
.nav-pills > li > a.active,
.nav-pills > li > a.active:focus,
.nav-pills > li > a.active:hover {
  background: #6c757d;
  color: #fff;
}
.nav > li > a {
  color: #bfb8b9;
}
.table > caption + thead > tr:first-child > td,
.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > td,
.table > thead:first-child > tr:first-child > th {
  border-top: 0;
  border-bottom: 0 !important;
}
.nav-pills {
  margin-bottom: 2px !important;
}

.header-button {
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.25px;
  font-family: Poppins, sans-serif;
  font-style: normal;
}
.input-error {
  border-color: #ff0018 !important;
  border-style: solid !important;
  border-width: 1px !important;
}
.input-error-label {
  color: #ff0018 !important;
  font-family: Poppins, sans-serif !important;
  display: block;
}
.language-select .select2-container {
  width: 100% !important;
}
</style>

<style scoped>
#content-scroll {
  overflow: auto !important;
  scroll-behavior: smooth;

  /* Hide scrollbar */
  -ms-overflow-style: none; /* for IE and Edge */
  scrollbar-width: none; /* for Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
#content-scroll::-webkit-scrollbar {
  display: none;
}

i[class*="arrow"]{
    display: inline-block;
    padding: 2.5px;
    border-width: 0px 0px 2.5px 2.5px !important;
}
.examples-button > .arrow {
    border: solid rgba(0, 17, 51, 0.6);;
  margin: 0px 4px 1px 4px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.examples-button.show >.arrow{
  border: solid rgb(255, 97, 0);
  margin: 4px 4px 0px 4px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.dropdown {
  position: relative;
  display: inline-block;
}

.examples-button {
  width: 250px;
  display: block;
  background: #FFFFFF;
  border: 2px solid rgba(0, 17, 51, 0.15);;
  border-radius: 6px;
}
.examples-button.show{
  border: 2px solid rgb(255, 97, 0) ;
}
.examples-button > div{
  text-align: left;
  display: inline-block;
  width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle;
}
#maincontent-scrollbar {
  width: 100%!important;
  height: 100% !important; 
  overflow: auto;
}


/* 
  .select2-container--default .select2-selection--single {
  width: 180px !important;
  background-color: #2d353c;
  border: 1px solid #aaa;
  border-radius: 4px;
  color: #e9ecef;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #e9ecef;
  line-height: 28px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #e9ecef transparent transparent transparent !important;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.select2.select2-container--focus .select2-selection--single:focus {
  border-color: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
  width: 124px !important;
} */
</style>

