<template>
  <div>
    <label for="password" class="form-label">Password</label>
      <ValidationProvider
      name="Password"
      :rules="{ required: true }"
      v-slot="{ errors }"
      >
        <div class="input-group">
          <input class="form-control form-control-lg"
            @input="$emit('inputVal', $event)"
            :type="inputType" 
            id="password" 
            ref="password" 
            style="border-right: none !important;"
            required
          >
          <div class="input-group-addon" 
            @mousedown="toggleShowPass(true)" @mouseup="toggleShowPass(false)" @mouseleave="toggleShowPass(false)"
            title="Show/hide password"                    
            style="cursor: pointer;"
          >
            <span>
              <i :class="eyeClass" aria-hidden="true" style="padding: 8px 2px;"></i>
            </span>
          </div>
        </div>
      <span class="text-danger">{{ errors[0] }}</span>
    </ValidationProvider>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inputType: 'password',
      eyeClass: 'fa fa-eye'
    }
  }, 
  methods: {
    toggleShowPass(val) {
      this.inputType = val ? 'text' : 'password'
      this.eyeClass = val ? 'fa fa-eye-slash' : 'fa fa-eye'
      this.$refs.password.focus();
    }
  },
}
</script>

<style>

</style>