var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content",attrs:{"id":"content"}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-block"},[(this.sessionExpired)?_c('div',[_c('h4',{staticClass:"card-subtitle my-5 text-center"},[_vm._v(" Your session has expired. Please log in. ")])]):_c('div',[_c('h4',{staticClass:"card-title text-center m-t-20"},[_vm._v("Welcome back!")]),_vm._m(0)]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"form-group m-b-20"},[_c('label',{staticClass:"form-label",attrs:{"for":"userId"}},[_vm._v("Email")]),_c('ValidationProvider',{attrs:{"name":"Email","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.loginForm.userId),expression:"loginForm.userId"}],staticClass:"form-control form-control-lg",class:classes,attrs:{"type":"email","id":"userId","required":""},domProps:{"value":(_vm.loginForm.userId)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.loginForm, "userId", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group m-b-20"},[_c('InputPassword',{on:{"inputVal":function($event){_vm.loginForm.password = $event.target.value}},model:{value:(_vm.loginForm.password),callback:function ($$v) {_vm.$set(_vm.loginForm, "password", $$v)},expression:"loginForm.password"}})],1),_c('div',{staticClass:"form-group m-b-20"},[_c('img',{attrs:{"src":_vm.captchaImg,"alt":""}}),_c('i',{staticClass:"fa fa-redo",staticStyle:{"font-size":"20px","margin-left":"2%","cursor":"pointer"},on:{"click":function($event){return _vm.refreshCaptcha()}}})]),_c('div',{staticClass:"form-group m-b-20"},[_c('label',{staticClass:"form-label",attrs:{"for":"captcha"}},[_vm._v("Enter characters above")]),_c('ValidationProvider',{attrs:{"name":"characters","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.loginForm.captcha),expression:"loginForm.captcha"}],staticClass:"form-control form-control-lg",class:classes,attrs:{"type":"text","id":"captcha","required":""},domProps:{"value":(_vm.loginForm.captcha)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.login()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.loginForm, "captcha", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('h6',{staticClass:"card-subtitle m-b-20 text-center ria-color"},[_c('router-link',{attrs:{"to":{ name: 'resetPassword' }}},[_vm._v(" Forgot your password? ")])],1),_c('button',{staticClass:"btn",class:invalid ? 'login-btn-disable' : 'login-btn-enable',attrs:{"type":"button","disabled":invalid},on:{"click":function($event){return _vm.login()}}},[_c('i',{attrs:{"id":"loginBtnLbl"}}),_vm._v("    Log in ")])])]}}])})],1)]),_c('QrModel',{attrs:{"qrCode":_vm.qrCode,"email":_vm.loginForm.userId,"type":"signin"}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h6',{staticClass:"card-subtitle m-b-20 text-center",staticStyle:{"margin-right":"29%"}},[_vm._v(" New at Dandelion? "),_c('span',{staticClass:"ria-color"},[_c('a',{attrs:{"href":"/magnolia/signup"}},[_vm._v("Sign up")])])])}]

export { render, staticRenderFns }