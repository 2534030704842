<template>
  <div class="tab-content" style="margin-top: -5%; background: transparent;">
    <!-- begin tab-pane -->
    <div class="tab-pane fade active show" id="nav-pills-tab-1">
      <!--<span v-text="responseObject.data"></span>-->
      <json-viewer :value="responseObject.data" :expand-depth="10" :copyable="{copyText: 'copy',copiedText: 'copied',timeout: 2000,}"
                    :expanded="true"                    
                    style="background: transparent;"
                    class="jv-container jv-light ria"
                  ></json-viewer>
    </div>
    <!-- end tab-pane -->
    <!-- begin tab-pane -->
    <div class="tab-pane fade" id="nav-pills-tab-2">
      <div class="table-responsive ria text-dark">
        <table class="table table-invoice">
          <tbody>
            <tr
              v-for="(key, value, index) in responseObject.headers"
              v-bind:key="index"
            >
              <td class="text-left table-tr-td">{{ value }}</td>
              <td class="text-right table-tr-td" >{{ key }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- end tab-pane -->
  </div>
</template>
<script>
import Constant from "../plugin/Constant";
import Utils from "../plugin/Utils";
import JsonViewer from "vue-json-viewer/ssr";
import "vue-json-viewer/style.css";
export default {
  props: {
    responseObject:{      
    },
  },
  data: function(){
    return {
    };
  },
  async mounted(){
    await swtichTheme.init();
  },
  components:{
    JsonViewer,
  },
  methods: {
  },
  beforeMount(){
  },
};
</script>
<style scoped>
.table-tr-td {
  border-color: #6c757d !important;
  padding: 10px 15px;
  width: 1%;
}
.table {
    border-color: #e2e7eb;
    border-radius: 3px;
}
</style>