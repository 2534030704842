<template>
  <div class="workflow-id-3">
    <div class="block1">
       {{workflowContent.block1Title}}
    </div>
    <arrowVue :direction="'right'" class="arrow-blue" left="false"/>
    <div class="block2">
        {{workflowContent.block2Title}}
    </div>
    <arrowVue :direction="'right'" class="arrow-black"/>
    <div class="block3">
         <img class="riaLogo" :src="appState.getImage('riaLogoDark')" :style="{'min-width': '150px', width: '50%'}"/>
    </div>
  </div>
</template>
<script>

import arrowVue from './arrow.vue';
export default {
    name: 'WorkflowId3',
    inject:  ["appState"],
    props: ['workflowContent'],
    components:{
        arrowVue
    },
    data(){
        return {};
    },
    computed:{},
    methods:{},
        async mounted(){
        await swtichTheme.init();
    }
}
</script>
<style>
 .workflow-id-3 .line-horizontal{
     left: 0px !important;
 }
</style>
<style scoped>
.workflow-id-3{
    display: flex;
    justify-content: space-around;
    width: 100%;
    font-family: 'Nunito Sans';
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;  
    color:rgba(0, 17, 51, 0.8); 
}
[class*="block"]{
     background: rgba(0, 17, 51, 0.02);
     display: flex;
    justify-content: center;
    align-items: center;
}
.block1, .block3{
    width: 25%;
    border: 1px dashed;
    height: 56px;
}
.block2{
    width: 30%;
}

.block3{
    border-color: #ff6100;
}

.block1{
    border-color: #00AAFF;
}
[class*="arrow-"]{
    width: 15%;
}
.arrow-black > div{
    width: 15%;
    left: 0px !important;
}
.arrow-black{
    --arrow-color: black;
}

.arrow-blue{
    --arrow-color: #00AAFF;
}

</style>