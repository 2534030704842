<template>
<div class="api-tab-container">
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <a href="#default-tab-2" data-toggle="tab" id="tab2" class="nav-link ria api-tab-text-dark active" >
        <span class="d-sm-none">Tab 2</span>
        <span class="d-sm-block d-none ria text-dark">Header & Query</span>
      </a>
      
    </li>
    <li class="nav-item" v-show="requestBody">
      <a data-toggle="tab" href="#default-tab-1" class="nav-link ria api-tab-text-dark">
        <span class="d-sm-none">Tab 1</span>
        <span class="d-sm-block d-none ria text-dark">Request Body</span>
      </a>
    </li>
    <li class="nav-item">
        <a data-toggle="tab" href="#default-tab-4" class="nav-link ria api-tab-text-dark">
            <span class="d-sm-none">Tab 4</span>
            <span class="d-sm-block d-none ria text-dark">Response</span>
        </a>
    </li>
    <li class="nav-item" v-show="showExample">
      <a href="#default-tab-3" data-toggle="tab" class="nav-link ria api-tab-text-dark">
        <span class="d-sm-none">Tab 3</span>
        <span class="d-sm-block d-none ria text-dark">Examples</span>
      </a>
    </li>
  </ul>
</div>
</template>
<style scoped>
.api-tab-container {
  background-color: transparent !important;
  min-height: 50px;
  font-weight: 600;
  letter-spacing: 0.5px;
}
.api-tab-container .nav-tabs{
  background-color: transparent !important;
}
.api-tab-container .nav-tabs .nav-item {
  margin-bottom: -2px;
}
.api-tab-container ul {
  border-bottom: 2px solid #999 !important;
}
.nav-tabs>li>a {
    margin-right: unset !important;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    /* color: #495057; */
    /* background-color: rgb(39, 46, 60) !important; */
    /* border-color: rgb(39, 46, 60) !important; */
    /* background-color: unset !important; */
    /* font-weight: 600; */
    /* border: 3px solid rgb(255, 97, 0); */
}

.nav-tabs .nav-link {
    /* border: 0px solid transparent; */
    border-top-left-radius: unset;
    border-top-right-radius: unset;
}
/* .nav-link.active {
  border-bottom: 3px solid rgb(255, 97, 0);
} */
.api-tab-container .nav-tabs .nav-item .nav-link {
  color: #999;
  border-bottom: 2px solid #999 !important;
}
.api-tab-container .nav-tabs .nav-item .nav-link.active {
  /* color: rgb(255, 97, 0) !important; */
  background-color: transparent !important;
  border-bottom: 2px solid rgb(255, 97, 0) !important;
}
.api-tab-container .nav-tabs .nav-item .nav-link.active span.ria{
  color: rgb(255, 97, 0) !important;
}
</style>

<script>

export default {

  data() {
    return {
      activetabClass: 'active'
    }
  },
  props: ["requestBody", "showExample"],
  async mounted(){
    await swtichTheme.init();
  },
  watch: {
    requestBody: function(oldVal, newVal){
     let activeClasses = document.getElementsByClassName("ria active");
     for(let i = 0; i < activeClasses.length; i++) {
       activeClasses[i].classList.remove("active");
     }
      let tab = document.getElementById("tab2");
      let childTab1 = document.getElementById("default-tab-1");
      let childTab2 = document.getElementById("default-tab-2");
      let childTab3 = document.getElementById("default-tab-3");
      let childTab4 = document.getElementById("default-tab-4");
      if(tab) {
        tab.classList.add("active");
        childTab2.classList.add("active", "show");
        childTab1.classList.remove("active", "show");
        childTab3.classList.remove("active", "show");
        childTab4.classList.remove("active", "show");
      }
    }
  }
}

</script>