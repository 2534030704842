import { render, staticRenderFns } from "./div-with-arrow.vue?vue&type=template&id=1ca8adc7&scoped=true&"
import script from "./div-with-arrow.vue?vue&type=script&lang=js&"
export * from "./div-with-arrow.vue?vue&type=script&lang=js&"
import style0 from "./div-with-arrow.vue?vue&type=style&index=0&id=1ca8adc7&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ca8adc7",
  null
  
)

export default component.exports