/* eslint-disable */

"use strict";

import ClipboardJS from "clipboard";
import Constant from './Constant';

var _ = require("lodash");
const apiConverter = require("api-spec-converter");
const download = require("js-file-download");
const Converter = require("openapi-to-postmanv2");
const CryptoJS = require("crypto-js");
const { EncryptStorage } = require('encrypt-storage');
const uuidv4 = require('uuid/v4');

const nacl = require("tweetnacl");
nacl.util = require("tweetnacl-util");
const textEncoder = new TextEncoder("utf-8");
const textDecoder = new TextDecoder("utf-8");

const KEY = "12345678900000001234567890000000"; //32 bit
const IV = "1234567890000000"; //16 bits
const jp = require("jsonpath-plus");

const htmlEntities = [
    { regex: /&/g, entity: '&amp;' },
    { regex: />/g, entity: '&gt;' },
    { regex: /</g, entity: '&lt;' },
    { regex: /á/g, entity: '&aacute;' },
    { regex: /é/g, entity: '&eacute;' },
    { regex: /í/g, entity: '&iacute;' },
    { regex: /ó/g, entity: '&oacute;' },
    { regex: /ú/g, entity: '&uacute;' }
];

var apiSpecConverter = function(fromApiType, toApiType, data) {
    return new Promise(function(resolve, reject) {
        apiConverter.convert({
            from: fromApiType,
            to: toApiType,
            source: data,
        }).then(function(converted) {
            resolve(converted);
        });
    });
}

var isEmpty = function(data) {
    return _.isEmpty(data);
}

var initalizeClipboardJs = function() {
    new ClipboardJS(".copyBtn", {
        text: function(trigger) {
            return self.code;
        },
    });
    $('.copyBtn').click(function() {
        $(this).text('copied').addClass(self.copiedClass).removeClass(self.copyClass);
        setTimeout(() => {
            $(this).text('copy').removeClass(self.copiedClass).addClass(self.copyClass);
        }, 2000);
    });
}

var fileDownload = function(data, fileName) {
    download(data, fileName);
}

var postmanCollection = function(openapi) {
    Converter.convert({ type: "string", data: openapi }, {},
        (err, conversionResult) => {
            if (!conversionResult.result) {
                console.log("Could not convert", conversionResult.reason);
            } else {
                fileDownload(
                    JSON.stringify(conversionResult.output[0].data, null, 2),
                    openapi.info.title + "_" + _.now() + ".json"
                );
            }
        }
    );
}

var setSessionValue = function(key, value) {
    sessionStorage.setItem(key, value);
}

var getSessionValue = function(key) {
    return new Promise((resolve, reject) => {
        resolve(sessionStorage.getItem(key));
    });
}

var getParameters = function(apiResult) {
    var parameterArray = [];

    $.each(apiResult, function(key, value) {
        parameterArray.push(value);
    });

    var inParam = _.mapValues(_.groupBy(parameterArray, "in"), (paramlist) =>
        paramlist.map((param) => _.omit(param, "in"))
    );
    return inParam;
}

var generateHeaderForm = function(object) {
    let headerForm = {};
    $.each(object, function(key, value) {
        let object = {
            [value.name]: { "type": value.schema.type, "description": value.description, "title": value.name, "default": value.schema.default, "enum": value.schema.enum, "required": value.required, "format": value.schema.format, "maxLength": value.schema.maxLength, "minLength": value.schema.minLength }
        };
        headerForm = $.extend(headerForm, object);
    });
    return headerForm;
}

var getEnviromentURL = function(key) {
    return new Promise(function(resolve, reject) {
        let enc = encryptStorage().getSession();
        let result = enc.getItem(Constant.config.UI_CONFIG);
        if (!isEmpty(result)) {
            let value = _.filter(result, function(object) {
                return object.propertyName === Constant.config.SANDBOX_ENV_CONFIG;
            })[0];
            if (!_.isEmpty(value)) {
                let propertyObject = JSON.parse(value.propertyValue);
                $.each(propertyObject, function(envKey, envValue) {
                    if (key === envValue.text) {
                        resolve(envValue.value);
                    }
                });
            } else {
                console.error('Object value is empty %s for key : %s ', value, Constant.config.SANDBOX_ENV_CONFIG);
            }
        }
    });
}

var addSpinner = function(target) {
    if (!$(target).hasClass("panel-loading")) {
        var targetBody = $(target).find(".panel-body");
        var spinnerHtml =
            '<div class="panel-loader"><span class="spinner-small"></span></div>';
        $(target).addClass("panel-loading");
        $(targetBody).prepend(spinnerHtml);
    }
}

var removeSpinner = function(target) {
    $(target).removeClass("panel-loading");
    $(target).find(".panel-loader").remove();
}


/**
 * Get Encrypted password
 * @param {*} password 
 */
var getEncryptedPassword = function(password) {
    return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(CryptoJS.SHA256(password).toString()));
}

var createDataTable = async function(id) {
    await $('.' + id).dataTable({
        "bDestroy": true
    }).fnDestroy();
    await setTimeout(() => {
        $("#" + id).DataTable({ responsive: true, order: [1, 'asc'] });
    }, 200);
}

var clearSession = function() {
    //encryptStorage().getSession().clear();
    encryptStorage().initSession();
    //sessionStorage.clear();
    //localStorage.clear();
}

var encryptStorage = function() {
    "use strict";
    return {
        initSession: function() {
            sessionStorage.setItem(Constant.config.SESSION_ID, uuidv4());
        },
        getSession: function() {
            return {
                setItem: function(key, value) {
                    let encJson = CryptoJS.AES.encrypt(JSON.stringify(value), sessionStorage.getItem(Constant.config.SESSION_ID)).toString();
                    let encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson));
                    sessionStorage.setItem(key, encData);
                },
                getItem: function(key) {
                    try {
                        const cryptoInfo = sessionStorage.getItem(key);
                        if (!isEmpty(cryptoInfo)) {
                            let decData = CryptoJS.enc.Base64.parse(cryptoInfo).toString(CryptoJS.enc.Utf8);
                            let bytes = CryptoJS.AES.decrypt(decData, sessionStorage.getItem(Constant.config.SESSION_ID)).toString(CryptoJS.enc.Utf8);
                            return JSON.parse(bytes);
                        } else {
                            return {};
                        }
                    } catch (error) {
                        console.warn(error);
                        return {};
                    }
                },
                clear: function() {
                    sessionStorage.clear();
                }
            }
        },
        initLocal: function() {
            localStorage.setItem(Constant.config.SESSION_ID, uuidv4());
        },
        getLocal: function() {
            return EncryptStorage(sessionStorage.getItem(Constant.config.SESSION_ID), { 'storageType': 'localStorage' });
        }
    }
}

var addInputValidation = async function() {
    let count = 0;
    let nonEmpty = 0;
    await $(":input").each(function() {
        if ($(this).prop("required")) {
            if (isEmpty($(this).prop("value"))) {
                let fieldName = "";
                if ($(this).prop("name").includes('.')) {
                    let name = $(this).prop("name").split('.');
                    fieldName = name[name.length - 1];
                } else {
                    fieldName = $(this).prop("name");
                }
                $(this).addClass("input-error");
                $(this)
                    .parent()
                    .find(".jsonform-errortext")
                    .addClass("input-error-label").css("display", "block")
                    .text(fieldName + " is required");
            } else {
                $(this).removeClass("input-error");
                $(this)
                    .parent()
                    .find(".jsonform-errortext").css("display", "none")
                    .removeClass("input-error-label")
                    .text("");
                nonEmpty += 1;
            }
            count += 1;
        }
        if ($(this).prop("name") === 'Ocp-Apim-Subscription-Key') {
            $(this).removeClass("input-error");
            $(this)
                .parent()
                .find(".jsonform-errortext").css("display", "none")
                .removeClass("input-error-label")
                .text("");
        }
    });

    await $('#btnTryItOut').prop('disabled', count === nonEmpty ? false : true);

}

/**************************************************************
 *string encryption
 * str: string that needs to be encrypted
 ****************************************************************/
var Encrypt = async function(str) {
    var key = CryptoJS.enc.Utf8.parse(KEY);
    var iv = CryptoJS.enc.Utf8.parse(IV);

    var encrypted = '';

    var srcs = CryptoJS.enc.Utf8.parse(str);
    encrypted = CryptoJS.AES.encrypt(srcs, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return await encrypted.ciphertext.toString();
}

/**************************************************************
 *string decryption
 * str: the string to be decrypted
 ****************************************************************/
var Decrypt = async function(str) {
    var key = CryptoJS.enc.Utf8.parse(KEY);
    var iv = CryptoJS.enc.Utf8.parse(IV);
    var encryptedHexStr = CryptoJS.enc.Hex.parse(str);
    var srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
    var decrypt = CryptoJS.AES.decrypt(srcs, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    var decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return await decryptedStr.toString();
}

var getMinDiff = function(obj) {
    var date = new Date(Math.floor((obj.toString().substring(obj.toString().lastIndexOf('-') + 1) / 1000.0)) * 1000);
    var currentDate = new Date();
    return (Math.round(currentDate.getTime() - date.getTime()) / 60000) < 1;
};

var generateSignature = function(id, object) {
    let keys = nacl.sign.keyPair.fromSeed(textEncoder.encode(id.replaceAll("-", "")));
    return nacl.util.encodeBase64(nacl.sign.detached(textEncoder.encode(JSON.stringify(object)), keys.secretKey))
};

var verifySignature = function(requestId, signature, data) {
    let keys = nacl.sign.keyPair.fromSeed(textEncoder.encode(requestId.toString().substring(0, requestId.toString().lastIndexOf('-')).replaceAll("-", "")));
    return nacl.sign.detached.verify(nacl.util.decodeUTF8(JSON.stringify(data)), nacl.util.decodeBase64(signature.toString()), keys.publicKey);
};

var unescapeHTML = function(str) {
    let flag = false;
    if (typeof str === 'object') {
        str = JSON.stringify(str);
        flag = true;
    }
    for (let v in htmlEntities) {
        str = str.replace(htmlEntities[v].regex, htmlEntities[v].entity);
    }
    return flag ? JSON.parse(str) : str;
}

var idleLogout = function() {
    var t;
    window.onload = resetTimer;
    window.onmousemove = resetTimer;
    window.onmousedown = resetTimer; // catches touchscreen presses as well      
    window.ontouchstart = resetTimer; // catches touchscreen swipes as well 
    window.onclick = resetTimer; // catches touchpad clicks as well
    window.onkeydown = resetTimer;
    window.onmouseover = resetTimer;
    window.addEventListener('scroll', resetTimer, true); // improved; see comments

    function loginPage() {
        window.location.href = "/magnolia/ria-portal/login";
    }

    function resetTimer() {
        clearTimeout(t);
        t = setTimeout(loginPage, (30 * 60000)); // time is in milliseconds
    }
};

var adminResource = function () {
    let basePath = process.env.PUBLIC_PATH;
    $.when(
        $.getScript("../../" + basePath + "/plugins/switchery/switchery.min.js"),
        $.getScript("../../" + basePath + "/js/demo/form-slider-switcher.demo.min.js"),
        /*$.getScript("../../" + basePath + "/js/rapipdf-min.js"),*/
        $.getScript("../../" + basePath + "/plugins/DataTables/media/js/jquery.dataTables.js"),
        $.getScript("../../" + basePath + "/plugins/DataTables/media/js/dataTables.bootstrap.min.js"),
        $.getScript("../../" + basePath + "/js/demo/dashboard.min.js"),
        $.getScript("../../" + basePath + "/js/demo/dashboard-v2.min.js"),
        $.getScript("../../" + basePath + "/plugins/jquery-jvectormap/jquery-jvectormap-world-merc-en.js"),
        $.getScript("../../" + basePath + "/plugins/bootstrap-calendar/js/bootstrap_calendar.min.js"),
        $.getScript("../../" + basePath + "/plugins/jquery-jvectormap/jquery-jvectormap-world-merc-en.js"),
        $.getScript("../../" + basePath + "/plugins/jquery-jvectormap/jquery-jvectormap.min.js"),
        $.getScript("../../" + basePath + "/css/ria/style.css"),
        $.Deferred(function (deferred) {
            $(deferred.resolve);
        })
    )
        .done(function () {
            console.log("Successfully to load js files.");
            var scrrenWidth = $(window).width();
            if (scrrenWidth < 1200) {
                document.body.style.zoom = "80%";
            } else {
                document.body.style.zoom = "90%";
            }
            //App.init();
        })
        .fail(function (jqxhr, settings, exception) {
            console.log("Failed to load js files : " + exception);
        });
}

var base64 = function (file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

var addImageZoomPlugin = function () {
    $(document).ready(function () { $('#imageZoom').imageZoom(); });
};

var extractJsonNode = function (jsonObj, pathExpr) {
    return jp.JSONPath({
        json: jsonObj,
        path: pathExpr
    });
};

export default {
    apiSpecConverter,
    initalizeClipboardJs,
    fileDownload,
    postmanCollection,
    setSessionValue,
    getSessionValue,
    getEncryptedPassword,
    getParameters,
    generateHeaderForm,
    getEnviromentURL,
    addSpinner,
    removeSpinner,
    createDataTable,
    isEmpty,
    clearSession,
    encryptStorage,
    addInputValidation,
    Encrypt,
    Decrypt,
    getMinDiff,
    generateSignature,
    verifySignature,
    unescapeHTML,
    idleLogout,
    adminResource,
    base64,
    addImageZoomPlugin,
    extractJsonNode
}