<template>
  <!-- begin #content -->
  <div id="content" class="content">
    <api-dropdown :apiVersion="this.apiVersion" v-if="this.apiVersion" />
    <EditableArea v-if="main" v-bind:content="main" />
    <ViewImage
      v-show="imagePath != '' && imageTitle != ''"
      :imagePath="imagePath"
      :imageTitle="imageTitle"
    ></ViewImage>
  </div>
</template>

<script>
import Constant from "../plugin/Constant";
import { EditableArea } from "../vue-editor";
import ViewImage from "../components/ViewImage.vue";
import ApiDropdown from "../components/ApiVersionsDropdown.vue";
import "highlight.js/styles/a11y-light.css";
import Utils from "../plugin/Utils";
export default {
  name: "Basic",
  components: {
    EditableArea,
    ViewImage,
    ApiDropdown,
  },
  data() {
    return {
      imageTitle: "",
      imagePath: "",
    };
  },
  props: [
    "startMenuName",
    "title",
    "metadata",
    "main",
    "extras",
    "demoURL",
    "apiVersion",
  ],
  created() {
    document.title = this.title;
  },
  mounted: function () {
    let self = this;
    swtichTheme.init();
    $("#viewImage").click(function () {
      self.imageTitle = $(this).attr("title");
      self.imagePath =
        sessionStorage.getItem(Constant.config.MAGNOLIA_URL) +
        $(this).find("p input").val();
        $("#modal-alert").appendTo("body").modal("show");
        Utils.addImageZoomPlugin();
    });
  },
};
</script>
<style scoped>
code {
  background-color: transparent !important;
}
.hljs-string,
.hljs-literal {
  color: #f26424 !important;
}
</style>

