import Basic from './pages/Basic';
import MethodsList from './pages/MethodsList';
import Image from './components/Image';
import Headline from './components/Headline';
import Paragraph from './components/Paragraph';
import List from './components/List';
import Item from './components/Item';
import Expander from './components/Expander';
import Login from './authenticate/Login';
import InternalLogin from './authenticate/InternalLogin';
import Signup from './authenticate/Signup';
import ContactSupportPage from './pages/ContactSupportPage';
import Workflow from './workflow/workflow.vue';
const config = {
  componentMappings: {
    // Pages
    'ria-portal-lm:pages/basic': Basic,
    'ria-portal-lm:pages/methodsList': MethodsList,
    'ria-portal-lm:pages/login': Login,
    'ria-portal-lm:pages/internalLogin': InternalLogin,
    'ria-portal-lm:pages/signup': Signup,
    'ria-portal-lm:pages/contactSupportPage': ContactSupportPage,
    'ria-portal-lm:pages/workflowPage': Workflow,
    // Components
    'spa-lm:components/headline': Headline,
    'spa-lm:components/image': Image,
    'spa-lm:components/paragraph': Paragraph,
    'spa-lm:components/list': List,
    'spa-lm:components/listItem': Item,
    'spa-lm:components/expander': Expander,
  },
};

export default config;
