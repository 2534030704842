"use strict";

const SEPARATER = '/';
const MENU_URI = 'menu';
const GET_ALL = 'getAll';
const API_URI = 'api'
const API_DEF_URI = 'apiDefinition';
const PROPERTY = 'property';
const ERROR_LIST_URI = 'errorCodeList?id=api_errors';
const API_CALL_URI = 'callApi';
const API_GROUP_LIST = 'getGroupedApiList?productName={productName}&subscriptionName={subscriptionName}';
const API_GROUP_NAME_LIST = 'getGroupedNameApiList?productName={productName}&subscriptionName={subscriptionName}&groupName={groupName}';
const RIA_URI = 'riauser';
const API_LOGIN_URI = 'authenticate';
const CREATE_USER = 'create';
const VERIFY_CODE = 'verify';
const DASHBOARD_URI = 'dashboard';
const GRAPH = 'getGraph';
const PRODUCT = 'topProduct';
const SUBS = 'topSubs';
const API_DATA = 'topAPI';
const OPERATION = 'topOperation';
const CHANGE_PASSWORD = 'changePwd';
const RESET_PASSWORD = 'forgotpwd';
const GET_ENDPOINT = 'endpoint';
const PRIMARY_URI = 'regenPrimKey';
const SECONDARY_URI = 'regenSecKey';
const POST_CONTACT_SUPPORT = 'contactFormEmail';
const POST_CONTACT_SUPPORT_WITH_ATTACHMENT = 'contactFormEmailWithAttachment';
const ACCOUNT_UPDATE = 'accountUpd';

const url = {
    GET_MENU_LIST: MENU_URI + SEPARATER + GET_ALL,

    GET_API: API_URI + SEPARATER,

    GET_ERROR_LIST: ERROR_LIST_URI,

    GET_UI_CONFIF: API_URI + SEPARATER + PROPERTY,

    API_CALL_URI: API_URI + SEPARATER + API_CALL_URI,

    GET_ALL_API: API_URI + SEPARATER + GET_ALL,

    GET_API_GROUP: API_DEF_URI + SEPARATER + API_GROUP_LIST,

    GET_API_GROUP_NAME: API_DEF_URI + SEPARATER + API_GROUP_NAME_LIST,

    LOGIN_USER: RIA_URI + SEPARATER + API_LOGIN_URI,

    CREATE_USER: RIA_URI + SEPARATER + CREATE_USER,

    VERIFY_CODE: RIA_URI + SEPARATER + VERIFY_CODE,

    CHANGE_PASSWORD: RIA_URI + SEPARATER + CHANGE_PASSWORD,

    RESET_PASSWORD: RIA_URI + SEPARATER + RESET_PASSWORD,

    GET_DASHBOARD_GRAPH: DASHBOARD_URI + SEPARATER + GRAPH,

    GET_DASHBOARD_PRODUCT: DASHBOARD_URI + SEPARATER + PRODUCT,

    GET_DASHBOARD_SUBS: DASHBOARD_URI + SEPARATER + SUBS,

    GET_DASHBOARD_API_DATA: DASHBOARD_URI + SEPARATER + API_DATA,

    GET_DASHBOARD_OPERATION: DASHBOARD_URI + SEPARATER + OPERATION,

    GET_ENDPOINT: SEPARATER + GET_ENDPOINT,

    CHANGE_PRIMARY_KEY: RIA_URI + SEPARATER + PRIMARY_URI,

    CHANGE_SECONDARY_KEY: RIA_URI + SEPARATER + SECONDARY_URI,

    POST_CONTACT_SUPPORT: RIA_URI + SEPARATER + POST_CONTACT_SUPPORT,
    
    POST_CONTACT_SUPPORT_WITH_ATTACHMENT: RIA_URI + SEPARATER + POST_CONTACT_SUPPORT_WITH_ATTACHMENT,

    PROFILE_UPDATE_URI: RIA_URI + SEPARATER + ACCOUNT_UPDATE

}

const jsonPath = {
    REQUEST_BODY: '$.paths..*..*.requestBody.*.*',
    //RESPONSE_EXAMPLES: '$.paths..*..*.responses..*..*.*.examples',
    PARAMETERS: '$.paths..*..*.parameters',
    RESPONSE_BODY: '$.paths..*..*.responses.*.*.*',
    PATH: '$..paths',
    CONTENT_TYPE: '$..paths..content',
    METHOD: '$..paths..*',
    OPERATION_ID: '$..paths..*.operationId',
    RESPONSE_CODES: '$.paths..*..*.responses.*~',
    REQUEST_EXAMPLES: '$.paths..*..*.requestBody..examples',
    RESPONSE_EXAMPLES: '$.paths..*..*.responses..examples',
    RESPONSE_DESC: '$.paths..*..*.responses.{code}.description'

}

const config = {
    UI_CONFIG: 'UiConfig',
    RIA_CONFIG: 'SANDBOX_STYLE_RIA',
    REN_CONFIG: 'SANDBOX_STYLE_REN',
    SANDBOX_ENV_CONFIG: 'SANDBOX_ENV_CONFIG',
    AUTHORIZATION: 'authorization',
    JWT_TOKEN: 'JWT_TOKEN',
    USER_ID: 'USER_ID',
    USER_CONFIG: 'USER_CONFIG',
    PREV_USER_CONFIG: 'PREV_USER_CONFIG',
    PRODUCTION_LOGIN: "PRODUCTION_LOGIN",
    METHOD_LIST: 'METHOD_LIST',
    MENU_LIST: 'MENU_LIST',
    RIA_IMAGES: 'RIA_IMAGES',
    SESSION_ID: 'SESSION_ID',
    SERVER_URL: 'SERVER_URL',
    MAGNOLIA_URL: 'MAGNOLIA_URL',
    LOGIN_COOKIE: 'LOGIN_COOKIE',
    RIA_MODE: 'RIA_MODE',
    SESSION_EXPIRE_MINUTES: 'SESSION_EXPIRE_MINUTES',
    DEFAULT_SESSION_TIMEOUT: '30',
    SEND_MONEY_APIS: 'SEND_MONEY_APIS',
    PRODUCTION: 'Production'
}
export default {
    url,
    config,
    jsonPath
}