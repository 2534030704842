<template>
    <div>
        <h1> Invalid URL or Access Denied </h1>
    </div>
</template>
<script>

export default {
    name: 'Unauthorized',
    props: [],
    data(){
        return {};
    },
    computed:{},
    methods:{}
}
</script>

<style scoped>
div{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>