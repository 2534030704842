<template>
    <div class="dotted-div">
          <div class="title"> 
          <slot name="title">
              <span>
                  {{data.title || ''}}
              </span>
          </slot>
          </div>
        <div class="children">
            <div class="child-block">
          <slot name="child1">
            <div class="child">
               <span class="text"> {{data.child1Content}}</span>
            </div>
        </slot>
        </div>
        <div :style="{width: showArrow ? '2px' : '0px'}" :class="{show: showArrow}" class="line-vertical arrow-down"></div>
        <div class="child-block">
        <slot name="child2">
            <div class="child">
               <span class="text"> {{data.child2Content}}</span>
            </div>
        </slot>
        </div>
        </div>  
      </div>
</template>
<script>
export default {
    name: 'DivWithTwoChild',
    props: ['title', 'showArrow', 'data'],
    data(){
        return {};
    },
    computed:{},
    methods:{}
}
</script>

<style scoped>
.dotted-div{
    font-family: 'Nunito Sans';
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    height: 273px;
    border-radius: 8px;
    width: var(--workflowdiv-width);
    border: var(--workflowdiv-border);
}

.child{
    padding: 16px;
    width: 100%;
    height: 56px;
    background: var(--workflowdiv-child-bg-color);
    border-radius: 6px;
}

.text{
    height: 24px;
    color: var(--workflowdiv-child-text-color);
    display: flex;
    justify-content: center;
}
.title{
    text-align: center;
    letter-spacing: 0.15px;
    color: rgba(0, 17, 51, 0.8);
    height:24px;
}
.line-vertical {
    height: 25px; /* line length */
    position: relative;
    background:  var(--workflowdiv-child-bg-color);
    margin: auto auto 10px auto;
}


.arrow-down.show:before {
    content: "";
    position: absolute;
    width: 0px;
    height: 0px;
    border: 5px solid transparent;
    left: -4px;
    bottom: -15px;
    border-top: 15px solid  var(--workflowdiv-child-bg-color);;
}
.children{
    width: 100%;
}
.child-block{
    padding: 0px 10px;
}
</style>