<template>
  <div>
    <div>
      <ul class="nav nav-tab">
        <li class="nav-item">
          <a href="#nav-pills-tab-1" data-toggle="tab" class="nav-link active">
            <span class="d-sm-block d-none ria text-dark">Body</span>
          </a>
        </li>
        <li class="nav-item">
          <a href="#nav-pills-tab-2" data-toggle="tab" class="nav-link">
            <span class="d-sm-block d-none ria text-dark">Header</span>
          </a>
        </li>
      </ul>
    </div>
    <span class="ria text-dark" style="float: right; margin-top: -8%" v-show="responseObject.status">
      <i class="fa fa-fw f-s-10 m-r-5 fa-circle" :class="responseObject.status < 300 ? 'text-success' : 'text-danger'"></i>
      {{ responseObject.status + ' ' + getResponseDesc()}}</span
    >
  </div>
</template>
<script>

const status = require('http-status');

export default {
  props: ["responseObject"],
  methods: {
      getResponseDesc: function() {
          return status[this.responseObject.status];
      }
  }
};
</script>

<style scoped>
.nav-tab .nav-item .nav-link {
  color: #999;
  border-bottom: 2px solid #999 !important;
}
 .nav-tab .nav-item .nav-link.active {
  /* color: rgb(255, 97, 0) !important; */
  background-color: transparent !important;
  border-bottom: 2px solid rgb(255, 97, 0) !important;
}
.nav-tab .nav-item .nav-link.active span.ria{
  color: rgb(255, 97, 0) !important;
}
</style>