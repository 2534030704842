<template>
  <div class="apidropdown-container">
    <button type="button" class="btn btn-white btn-sm dropdown-toggle" data-toggle="dropdown"> 
      {{ this.apiVer ? "API version " + this.apiVer : "Api Version"}}
    </button>
    <ul class="dropdown-menu" role="menu">
      <li v-for="(item, index) in versions" :key="index">
        <span @click="sendVersion(item)">API version {{ item.apiVersion }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import Constant from "../plugin/Constant";
const DEPRECATED_TEXT = "This is a deprecated version of this API, we recommend using the latest version.";

export default {
  // receiving 'apiVersion' prop from Basic component. It represent current(selected or default) api version.
  props: [ "apiVersion" ], 
  data() {
    return {
      versions: '',
      apiVer: this.apiVersion,
      verCount: null,
    }
  },
  methods: {

    sendVersion(verItem) {
      if(verItem.apiVersion != this.apiVer) {
        // emit api version value to Sidebar component and set current val to apiVer variable
        this.$events.emit('apiVersionNo', verItem);
        this.apiVer = verItem.apiVersion;
        this.showDeprecatedAlert();
      }
    },

    showDeprecatedAlert() {
      // show Deprecetaed Alert in case latest version is not selected
      if(this.apiVer < this.verCount) {
        let alertExist = document.querySelector('#deprecated-alert') == null ? false : true; // check if alert is on the page to avoid append it multiple times
        if(!alertExist){ 
          let titleElem = document.querySelector('.Paragraph > div.ria');
          titleElem.insertAdjacentHTML('afterend', '<div id="deprecated-alert" class="alert alert-danger text-light alert-api">' + DEPRECATED_TEXT + '</div>');
        }
      }
    },

    getApiGroupFromObject(obj) {
      // find current api group object
      let result = "";
      Object.keys(obj).map(key => {
        if(obj[key].url === this.$route.fullPath)
          result = obj[key];
        });
      return result.apiGroupName ? result : '';
    },

    removeOtherApiGpoups(obj, groupName) {
      // delete results which don't containt provided api group name 
      // (this way we are dispaying dropdown api list only for current api group name. this needs to be definied in magnolia cms)
      Object.keys(obj).map((key) => {
        if(obj[key].apiGroupName != groupName)
          delete obj[key];
        });
      return obj;
    }
  },

  created() {
    let product = this.$route.path.split('/'); // get product from url path
    product.pop();
    let [empty, root, ...all] = product;
    fetch(
      sessionStorage.getItem(Constant.config.MAGNOLIA_URL) + process.env.VUE_APP_REST_ONEPAGENAV + all.join('/')) 
      .then(resp => resp.json())
      .then(result => {
        let objectResult = {};
        result['@nodes'].forEach(element => {
          if(result[element]['apiVersion']){
            objectResult[element] = // create tmp object with needed data only
              { apiVersion : result[element]['apiVersion'], 
                url: result[element]['@path'],
                id: result[element]['@id'],
                apiGroupName: result[element]['apiGroupName']
              }
          }
        });
        this.versions = objectResult;
        let currentObj = this.getApiGroupFromObject(this.versions);
        this.versions = this.removeOtherApiGpoups(this.versions, currentObj.apiGroupName);
        this.versions = Object.values(this.versions).sort((a, b) => a.apiVersion - b.apiVersion); // Sort by apiVersion value
        this.verCount = Object.keys(this.versions).length; // count api versions for particular product
        
        this.$events.emit('defaultApiVersionNo', this.versions[this.apiVer - 1]); 
        this.showDeprecatedAlert();
      })
      .catch(err=>console.log(err));
  }
}
</script>

<style scoped>
.apidropdown-container {
  display: inline-block;
  position: absolute;
  top: 25px;
  right: 32px;
  z-index: 100;
}
.dropdown-menu>li>span:hover {
  cursor: pointer;
}
</style>

<style>
.alert-api {
  padding: 0.6em 1em !important;
  border: solid 1px #d09f9e !important;
  margin-top: 25px;
}
</style>
